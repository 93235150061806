import React, { useEffect, useState } from 'react';
import { FaTimes } from 'react-icons/fa';
import { FiChevronLeft, FiChevronRight, FiChevronsLeft, FiChevronsRight } from 'react-icons/fi';
import { useLocation, useNavigate } from 'react-router-dom';
import { LIVE_URL } from '../../../config/config';
import useAuthToken from '../../../hooks/useAuthToken';
import styles from "./css/Report.module.scss";
import ProductTable from './datatable/ProductTable';

const Report = () => {
  const { token, loading } = useAuthToken();
  const navigate = useNavigate();
  const location = useLocation();

  // define state
  const [searchTerm, setSearchTerm] = useState(new URLSearchParams(location.search).get('search') || '');
  const [reports, setReports] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [filteredReports, setFilteredReports] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [entriesPerPage, setEntriesPerPage] = useState(10);
  const [limit, setLimit] = useState(10);
  const [sortField, setSortField] = useState('id');
  const [sortOrder, setSortOrder] = useState('ASC');

  useEffect(() => {
    const fetchReport = async () => {
      try {
        const queryParams = new URLSearchParams();
        if (searchTerm) {
          queryParams.set('search', searchTerm);
        }
        queryParams.set('page', currentPage);
        queryParams.set('pageSize', entriesPerPage);
        queryParams.set('sort_by', sortField);
        queryParams.set('order_by', sortOrder);
        queryParams.set('limit', limit);

        const response = await fetch(`${LIVE_URL}/admin/list-user-report?${queryParams}`, {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });

        const data = await response.json();

        if (response.ok && data.data && Array.isArray(data.data.user)) {
          setReports(data.data.user);
          setFilteredReports(data.data.user);
          setTotalPages(Math.ceil(data.data.pagination.totalCount / entriesPerPage));
        } else {
          setReports([]);
          setFilteredReports([]);
          setTotalPages(1);
        }
      } catch (error) {
        console.error('Error fetching reports:', error); 
        setReports([]);
        setFilteredReports([]);
        setTotalPages(1);
      }
    };

    if (!loading && token) {
      fetchReport();
    }
  }, [token, loading, currentPage, searchTerm, entriesPerPage, sortField, sortOrder]);

  useEffect(() => {
    if (reports) {
      const filtered = reports.filter(report =>
        report.user?.firstname.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredReports(filtered);
    }
  }, [searchTerm, reports]);

  const handleLimitChange = (event) => {
    const newLimit = parseInt(event.target.value, 10);
    setLimit(newLimit);
    setEntriesPerPage(newLimit);
    setCurrentPage(1);
    setTotalPages(Math.ceil(filteredReports.length / newLimit) || 1);
  };

  // // handle search click event
  // const handleSearchClick = () => {
  //   document.getElementById('search').focus();
  // }

  // handle clear search event
  const handleClearSearch = () => {
    setSearchTerm('');
    document.getElementById('search').focus();
    updateUrl('');
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handlePrevious = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNext = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  // Added function to go two pages back
  const handlePreviousTwo = () => {
    if (currentPage > 2) {
      setCurrentPage(currentPage - 2);
    } else if (currentPage === 2) {
      setCurrentPage(1);
    }
  };

  // Added function to go two pages forward
  const handleNextTwo = () => {
    if (currentPage < totalPages - 1) {
      setCurrentPage(currentPage + 2);
    } else if (currentPage === totalPages - 1) {
      setCurrentPage(totalPages);
    }
  };

  const getPageNumbers = () => {
    const pageNumbers = [];
    if (currentPage > 1) {
      pageNumbers.push(currentPage - 1);
    }
    pageNumbers.push(currentPage);
    if (currentPage < totalPages) {
      pageNumbers.push(currentPage + 1);
    }
    return pageNumbers;
  };

  const updateUrl = (search) => {
    const params = new URLSearchParams(location.search);
    if (search) {
      params.set('search', search);
    } else {
      params.delete('search');
    }
    navigate({ search: params.toString() });
  };

  useEffect(() => {
    updateUrl(searchTerm);
  }, [searchTerm]);

  return (
    <>
      <div className={`${styles.report}`}>
        <header>
          <h2>Report List Management</h2>
        </header>
        {/* entry section */}
        <section className={`${styles.section}`}>
          <div className={`${styles.user_entry}`}>
            <div className={`${styles.entries}`}>
              <label htmlFor="limit">Show</label>
              <select value={limit} onChange={handleLimitChange}>
                <option value="10">10</option>
                <option value="25">25</option>
                <option value="50">50</option>
                <option value="100">100</option>
              </select>
              <label htmlFor="entries">entries</label>
            </div>
            <div className={`${styles.search}`}>
              <label htmlFor="search">Search: </label>
              <input
                type="text"
                id='search'
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                // onClick={handleSearchClick}
              />
              {searchTerm && <FaTimes className={styles.closeIcon} onClick={handleClearSearch} />}
            </div>
          </div>

          {/* user information section */}
          <div className={`${styles.user_info}`}>
            <ProductTable
              apiEndpoint={`${LIVE_URL}/admin/list-user-report`}
              reports={filteredReports}
              setReports={setReports}
              rows={entriesPerPage}
              userName={searchTerm}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              sortField={sortField}
              sortOrder={sortOrder}
              setSortField={setSortField}
              setSortOrder={setSortOrder}
            />
          </div>

          {/* pagination section */}
          <div className={`${styles.pagination}`}>
            <button onClick={handlePreviousTwo} disabled={currentPage === 1}><FiChevronsLeft /></button>
            <button onClick={handlePrevious} disabled={currentPage === 1}><FiChevronLeft /></button>
            {getPageNumbers().map((page) => (
              <button
                key={page}
                onClick={() => handlePageChange(page)}
                className={currentPage === page ? styles.active : ''}
                disabled={page > totalPages}
              >
                {page}
              </button>
            ))}
            <button onClick={handleNext} disabled={currentPage === totalPages}><FiChevronRight /></button>
            <button onClick={handleNextTwo} disabled={currentPage === totalPages || currentPage === totalPages - 1}><FiChevronsRight /></button>
          </div>
        </section>
      </div>
    </>
  )
}

export default Report
