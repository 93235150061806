import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import React, { useEffect, useState } from 'react';
// import { BiSolidTrashAlt } from "react-icons/bi";
import { HiMiniPencilSquare } from "react-icons/hi2";
import { useNavigate, useParams } from 'react-router-dom';
import { LIVE_URL } from "../../../../config/config";
import useAuthToken from '../../../../hooks/useAuthToken';
import styles from './css/ProductTable.module.scss';

const ProductTable = ({ apiEndpoint, rows, searchText, plans, totalCount }) => {
    const { token, loading } = useAuthToken();
    const navigate = useNavigate();
    const { id } = useParams();

    const [products, setProducts] = useState([]);
    const [loadingData, setLoadingData] = useState(true);

    useEffect(() => {
        if (id) {
            const fetchPlans = async () => {

                try {
                    const response = await fetch(`${LIVE_URL}/admin/plan/${id}`, {
                        headers: {
                            'Authorization': `Bearer ${token}`,
                        },
                    });

                    const data = await response.json();

                    if (response.ok && data.data.plans) {
                        setProducts(data.data.plans);
                    } else {
                        setProducts([]);
                    }
                } catch (error) {
                    setProducts([]);
                } finally {
                    setLoadingData(false);
                }
            };

            fetchPlans();
        }
    }, [apiEndpoint, token, loading, id]);

    // Filter products based on searchText
    const filteredPlans = plans.filter(plan =>
        plan.title.toLowerCase().startsWith(searchText.toLowerCase())
    );

    // Example edit and delete handlers
    const handleEdit = (id) => {
        navigate(`/home/add-plan/${id}`);
    };

    const statusBodyTemplate = (rowData) => {
        return (
            <span className={rowData.status === 1 ? styles['status-publish'] : styles['status-unpublish']}>
                {rowData.status === 1 ? 'Publish' : 'UnPublish'}
            </span>
        );
    };
    const columns = [
        { field: 'id', header: 'Sr No.', className: styles['column-id'], sortable: true },
        { field: 'title', header: 'Plan Title', className: styles['column-title'], sortable: true },
        { field: 'amount', header: 'Plan Amount', className: styles['column-amount'], sortable: true },
        { field: 'day_limit', header: 'Day Limit', className: styles['column-limit'], sortable: true },
        { field: 'filter_include', header: 'Filter Include?', className: styles['column-filter'], sortable: true },
        { field: 'direct_chat', header: 'Direct Chat?', className: styles['column-direct-chat'], sortable: true },
        { field: 'chat', header: 'Chat?', className: styles['column-chat'], sortable: true },
        { field: 'is_like', header: 'Like Menu?', className: styles['column-like-menu'], sortable: true },
        { field: 'audio_video', header: 'Audio Video?', className: styles['column-audio-video'], sortable: true },
        {
            field: 'status',
            header: 'Plan Status',
            className: styles['column-status'],
            body: statusBodyTemplate,
            sortable: true
        },
        {
            field: 'action',
            header: 'Action',
            className: styles['column-action'],
            body: (rowData) => (
                <div className={styles['action-icons']}>
                    <HiMiniPencilSquare className={styles['user-icon']} onClick={() => handleEdit(rowData.id)} />
                    {/* <BiSolidTrashAlt className={styles['trash-icon']} onClick={() => handleDelete(rowData.id)} /> */}
                </div>
            ),
            sortable: false
        },
    ];

    return (
        <>
            <div className={`${styles.main}`}>
                <DataTable
                    className={`${styles.datatable}`}
                    value={filteredPlans}
                    rows={rows}
                    totalRecords={totalCount}
                    emptyMessage={<div className={styles['no-data-message']}>No data to display.</div>}
                >
                    {columns.map((col, i) => (
                        <Column
                            key={i}
                            field={col.field}
                            header={col.header}
                            sortable={col.sortable}
                            headerClassName={styles['column-header-small']}
                            bodyClassName={col.className}
                            body={col.body}
                        />
                    ))}
                </DataTable>
            </div>
        </>
    )
}

export default ProductTable;