import React from 'react';
import styles from './css/Spinner.module.scss';

const Spinner = () => {
    return (
        <div className={styles['spinner-container']}>
            <div className={styles.spinner}></div>
        </div>
    );
};

export default Spinner;
