import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { MdMarkEmailRead } from "react-icons/md";
import { useNavigate } from 'react-router-dom';
import { LIVE_URL } from '../../config/config';
import styles from "./css/OtpPage.module.scss";

const OtpPage = () => {
    // State variables to manage OTP input, errors, and popup messages
    const [otp, setOtp] = useState(['', '', '', '']);
    const [otpError, setOtpError] = useState('');
    const [popupMessage, setPopupMessage] = useState('');
    const navigate = useNavigate();
    const email = localStorage.getItem('email') || '';

    // Effect to handle popup message visibility with fade-out animation
    useEffect(() => {
        let timer;
        if (popupMessage) {
            const popupElement = document.getElementById('popup-message');
            if (popupElement) {
                popupElement.style.opacity = '1';
            }
            timer = setTimeout(() => {
                if (popupElement) {
                    popupElement.style.opacity = '0';
                }
                setTimeout(() => {
                    setPopupMessage('');
                }, 500);
            }, 2000);
        }
        return () => clearTimeout(timer);
    }, [popupMessage]);

    // Function to handle changes in the OTP input fields
    const handleOtpChange = (e, index) => {
        const { value } = e.target;
        const newOtp = [...otp];
        newOtp[index] = value.slice(0, 1);
        setOtp(newOtp);

        if (value && index < 3) {
            document.getElementById(`otp-input-${index + 1}`).focus();
        }
    };

    // Function to handle OTP form submission
    const handleOtpSubmit = async (e) => {
        e.preventDefault();
        const otpString = otp.join('');

        if (otpString.length < 4) {
            setOtpError(' OTP Is Required');
            setPopupMessage('OTP Is Required');
            return;
        } else {
            setOtpError('');
        }
        if (!email) {
            setPopupMessage('Email Is Not Available.');
            console.log("test", email)
            return;
        }
        try {
            const response = await axios.post(`${LIVE_URL}/admin/verify-otp`, { otp: otpString, email }, {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            });
            if (response.status === 200) {
                setPopupMessage('OTP Verified Successfully. You Can Now Reset Your Password.');
                setTimeout(() => {
                    navigate('/reset-password');
                }, 2000);
            } else {
                setPopupMessage('OTP Verification Failed.');
            }
        } catch (error) {
            if (error.response?.data?.message === 'OTP Not Matched!') {
                setPopupMessage('The Entered OTP Is Incorrect. Please Try Again.');
            } else {
                setPopupMessage(error.response?.data?.message || 'An Error Occurred');
            }
        }
    };

    return (
        <div className={`${styles.wrapper}`}>
              {/* Popup message */}
            <div id="popup-message" className={`${styles.otp_error_message} ${popupMessage.toLowerCase().includes('success') ? styles.success : styles.error}`}>
                {popupMessage && <p>{popupMessage}</p>}
            </div>
             {/* OTP Input Form */}
            <div className={`${styles.otpPage}`}>
                <div className={`${styles.main_icon}`}>
                    <MdMarkEmailRead />
                </div>
                <h3>Please Check Your Email</h3>
                <form onSubmit={handleOtpSubmit}>
                    <div className={`${styles.otpInputs}`}>
                        {otp.map((digit, index) => (
                            <input
                                key={index}
                                type="text"
                                id={`otp-input-${index}`}
                                value={digit}
                                onChange={(e) => handleOtpChange(e, index)}
                                maxLength="1"
                                className={otpError ? styles.error_input : ''}
                            />
                        ))}
                    </div>
                    {/* {otpError && <span className={`${styles.error_message}`}>{otpError}</span>} */}
                    <button type="submit">Verify OTP</button>
                </form>
            </div>
        </div>
    );
};

export default OtpPage;