import React, { useEffect, useState } from 'react';
import { FaStarOfLife } from "react-icons/fa";
import { GiNetworkBars } from "react-icons/gi";
import { IoMdSettings } from "react-icons/io";
import styles from "./css/Settings.module.scss";

const Settings = () => {
  // Define state variables
  const [fileName, setFileName] = useState('No file chosen');
  const [fileUrl, setFileUrl] = useState('');
  const [selectedOption, setSelectedOption] = useState('');
  const [formData, setFormData] = useState({
    websiteName: '',
    currency: '',
    userAppId: '',
    userApiKey: ''
  });
  const [errors, setErrors] = useState({});

  // Retrieve the image data from localStorage when the component mounts
  useEffect(() => {
    const storedFileName = localStorage.getItem('fileName');
    const storedFileUrl = localStorage.getItem('fileUrl');
    if (storedFileName && storedFileUrl) {
      setFileName(storedFileName);
      setFileUrl(storedFileUrl);
    }
  }, []);

  // Handle file input change
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setFileName(file.name);
      const reader = new FileReader();
      reader.onload = (e) => {
        const fileDataUrl = e.target.result;
        setFileUrl(fileDataUrl);
      };
      reader.readAsDataURL(file);
    }
  };

  // Handle text input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Handle select input change
  const handleSelectChange = (event) => {
    setSelectedOption(event.target.value);
  };

  // Handle form validation and edit profile
  const handleEditProfile = () => {
    const newErrors = {};
    if (!formData.websiteName) newErrors.websiteName = 'Website name is required';
    if (!formData.currency) newErrors.currency = 'Currency is required';
    if (!formData.userAppId) newErrors.userAppId = 'User App Id is required';
    if (!formData.userApiKey) newErrors.userApiKey = 'User Api Key is required';

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {
      setErrors({});
      alert("Profile updated successfully!");
    }
  };

  return (
    <>
      <div className={styles.settings}>
        <h2>Setting Management</h2>
        <div className={styles.card}>
          {/* General Information section */}
          <div className={styles.header}>
            <IoMdSettings className={styles.rotatingIcon} />
            <h4>General Information</h4>
          </div>

          <div className={styles.form}>
            {/* Website Name input */}
            <div className={styles.name}>
              <div className={styles.topLine}>
                <FaStarOfLife />
                <h5>Website Name</h5>
              </div>
              <input
                type="text"
                placeholder="Enter Store Name"
                name="websiteName"
                value={formData.websiteName}
                onChange={handleInputChange}
              />
              {errors.websiteName && <p className={styles.error}>{errors.websiteName}</p>}
            </div>

            {/* Website Image input */}
            <div className={styles.name}>
              <div className={styles.topLine}>
                <FaStarOfLife />
                <h5>Website Image</h5>
              </div>
              <div className={styles.fileInputContainer}>
                <input type="file" id="file" onChange={handleFileChange} />
                <label htmlFor="file">Choose File</label>
                <span id="file-name">{fileName}</span>
              </div>
              {fileUrl && <img src={fileUrl} alt="Chosen" className={styles.imagePreview} />}
            </div>

            {/* Timezone select input */}
            <div className={styles.name}>
              <label htmlFor="select">Select Timezone</label>
              <select id="select" value={selectedOption} onChange={handleSelectChange}>
                <option value="">Select an option</option>
                <option value="Option 1">Option 1</option>
                <option value="Option 2">Option 2</option>
                <option value="Option 3">Option 3</option>
                <option value="Option 4">Option 4</option>
              </select>
            </div>

            {/* Currency input */}
            <div className={styles.name}>
              <div className={styles.topLine}>
                <FaStarOfLife />
                <h5>Currency</h5>
              </div>
              <input
                type="text"
                placeholder="Enter Currency"
                name="currency"
                value={formData.currency}
                onChange={handleInputChange}
              />
              {errors.currency && <p className={styles.error}>{errors.currency}</p>}
            </div>
          </div>

          {/* One Signal Information section */}
          <div className={styles.signal_info}>
            <div className={styles.signal_header}>
              <GiNetworkBars />
              <h4>One Signal Information</h4>
            </div>

            <div className={styles.user_info}>
              {/* User App Id input */}
              <div className={styles.app_id}>
                <div className={styles.topLine}>
                  <FaStarOfLife />
                  <h5>User App Onesignal App Id</h5>
                </div>
                <input
                  type="text"
                  placeholder="Enter User App Id"
                  name="userAppId"
                  value={formData.userAppId}
                  onChange={handleInputChange}
                />
                {errors.userAppId && <p className={styles.error}>{errors.userAppId}</p>}
              </div>

              {/* User Api Key input */}
              <div className={styles.app_id}>
                <div className={styles.topLine}>
                  <FaStarOfLife />
                  <h5>User App Onesignal Api Key</h5>
                </div>
                <input
                  type="text"
                  placeholder="Enter User Api Key"
                  name="userApiKey"
                  value={formData.userApiKey}
                  onChange={handleInputChange}
                />
                {errors.userApiKey && <p className={styles.error}>{errors.userApiKey}</p>}
              </div>

              {/* Edit Profile button */}
              <button onClick={handleEditProfile}>Edit Profile</button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Settings;

