import React, { useEffect, useState } from 'react';
import { LIVE_URL } from '../../../config/config';
import useAuthToken from '../../../hooks/useAuthToken';
import styles from "./css/Dashboard.module.scss";
import Eight from "./images/eight.svg";
import Five from "./images/five.svg";
import Four from "./images/four.svg";
import Nine from "./images/nine.svg";
import One from "./images/one.svg";
import Seven from "./images/seven.svg";
import Six from "./images/six.svg";
import Three from "./images/three.svg";
import Two from "./images/two.svg";

const Dashboard = () => {
  // Custom hook to get auth token and loading state
  const { token, loading } = useAuthToken();

  // State to store the fetched dashboard data
  const [dashboardData, setDashboardData] = useState({
    users: 0,
    interests: 0,
    languages: 0,
    religion: 0,
    relation_goal: 0,
    faq: 0,
    pages: 0
  });

  // Fetch dashboard data from the API when component mounts or token changes
  useEffect(() => {
    async function fetchDashboardData() {
      try {
        const response = await fetch(`${LIVE_URL}/admin/dashboard/get-counts`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        if (data && data.data) {
          setDashboardData(data.data);
        }
      } catch (error) {
        console.error('Error fetching dashboard data:', error);
      }
    }
    if (!loading) {
      fetchDashboardData();
    }
  }, [token, loading]);

  return (
    <>
      <div className={`${styles.dashboard}`}>
        <h2>Report Data</h2>
        <div className={`${styles.dashboard_card}`}>
          <div className={`${styles.card}`}>
            <div className={`${styles.title}`}>
              <h3>Interest</h3>
              <h2>{dashboardData.interests}</h2>
            </div>
            <div className={`${styles.image}`}>
              <img src={One} alt="" />
            </div>
          </div>
          <div className={`${styles.card}`}>
            <div className={`${styles.title}`}>
              <h3>Language</h3>
              <h2>{dashboardData.languages}</h2>
            </div>
            <div className={`${styles.image}`}>
              <img src={Two} alt="" />
            </div>
          </div>
          <div className={`${styles.card}`}>
            <div className={`${styles.title}`}>
              <h3>Religion</h3>
              <h2>{dashboardData.religion}</h2>
            </div>
            <div className={`${styles.image}`}>
              <img src={Three} alt="" />
            </div>
          </div>
          <div className={`${styles.card}`}>
            <div className={`${styles.title}`}>
              <h3>Relation Goal</h3>
              <h2>{dashboardData.relation_goal}</h2>
            </div>
            <div className={`${styles.image}`}>
              <img src={Four} alt="" />
            </div>
          </div>
          <div className={`${styles.card}`}>
            <div className={`${styles.title}`}>
              <h3>FAQ</h3>
              <h2>{dashboardData.faq}</h2>
            </div>
            <div className={`${styles.image}`}>
              <img src={Five} alt="" />
            </div>
          </div>
          <div className={`${styles.card}`}>
            <div className={`${styles.title}`}>
              <h3>Total Plan</h3>
              <h2>3</h2>
            </div>
            <div className={`${styles.image}`}>
              <img src={Six} alt="" />
            </div>
          </div>
          <div className={`${styles.card}`}>
            <div className={`${styles.title}`}>
              <h3>Total Users</h3>
              <h2>{dashboardData.users}</h2>
            </div>
            <div className={`${styles.image}`}>
              <img src={Seven} alt="" />
            </div>
          </div>
          <div className={`${styles.card}`}>
            <div className={`${styles.title}`}>
              <h3>Total Pages</h3>
              <h2>{dashboardData.pages}</h2>
            </div>
            <div className={`${styles.image}`}>
              <img src={Eight} alt="" />
            </div>
          </div>
          <div className={`${styles.card}`}>
            <div className={`${styles.title}`}>
              <h3>Total Earning</h3>
              <h2>0</h2>
            </div>
            <div className={`${styles.image}`}>
              <img src={Nine} alt="" />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Dashboard

