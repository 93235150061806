
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { LIVE_URL } from "../../../config/config";
import useAuthToken from '../../../hooks/useAuthToken';
import Spinner from '../spinner/Spinner';
import styles from "./css/AddReligion.module.scss";

const Religion = () => {
  const { id } = useParams();
  const { token } = useAuthToken();

  const [title, setTitle] = useState('');
  const [selectedStatus, setSelectedStatus] = useState('');
  const [errors, setErrors] = useState({});
  const [popupMessage, setPopupMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  // Function to reset form fields
  const resetForm = () => {
    setTitle('');
    setSelectedStatus('');
    setPopupMessage('');
    setErrors({});
  };


  useEffect(() => {
    if (id) {
      const fetchReligionData = async () => {
        try {
          setLoading(true);
          const response = await fetch(`${LIVE_URL}/admin/religion/${id}`, {
            headers: {
              'Authorization': `Bearer ${token}`,
            },
          });
          const data = await response.json();
          if (response.ok) {
            const religionData = data.data;
            setTitle(religionData.title);
            setSelectedStatus(religionData.status ? 'true' : 'false');
          }
        } catch (error) {
          console.error('Error fetching religion data:', error);
        } finally {
          setLoading(false);
        }
      };
      fetchReligionData();
    } else {
      resetForm();
      setLoading(false);
    }
  }, [id, token]);

  useEffect(() => {
    let timer;
    if (popupMessage) {
      const popupElement = document.getElementById('popup-message');
      if (popupElement) {
        popupElement.style.opacity = '1';
      }
      timer = setTimeout(() => {
        if (popupElement) {
          popupElement.style.opacity = '0';
        }
        setTimeout(() => {
          setPopupMessage('');
        }, 500);
      }, 2000);
    }
    return () => clearTimeout(timer);
  }, [popupMessage]);

  const handleSelectChange = (event) => {
    setSelectedStatus(event.target.value);
    setErrors((prevErrors) => ({ ...prevErrors, selectedStatus: '' }));
  };

  const handleTitleChange = (event) => {
    setTitle(event.target.value);
    setErrors((prevErrors) => ({ ...prevErrors, title: '' }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    const newErrors = {};
    if (!title) newErrors.title = "🖋️ Don't Forget the Title!";
    if (!selectedStatus) newErrors.selectedStatus = '❗️Status field cannot be blank.';
    setErrors(newErrors);

    if (Object.keys(newErrors).length > 0) {
      setPopupMessage('Please fill in all required fields');
      setLoading(false);
      return;
    }

    const payload = {
      title: title,
      status: selectedStatus === 'true',
    };

    if (id) {
      payload.id = id;
    }

    const formData = new FormData();
    formData.append('title', title);
    if (id) formData.append('id', id);
    formData.append('type', "religion");
    formData.append('status', selectedStatus === 'true');

    try {
      const url = id
        ? `${LIVE_URL}/admin/religion/update`
        : `${LIVE_URL}/admin/religion/create`;

      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify(payload),
      });

      if (response.ok) {
        const successMessage = id ? 'Religion updated successfully.' : 'Religion saved successfully.';
        navigate(`/home/list-religion?message=${encodeURIComponent(successMessage)}`);
      } else {
        setPopupMessage(`Failed to save religion. Status: ${response.status}`);
      }
    } catch (error) {
      setPopupMessage('Error saving religion:', error);
    } finally {
      setLoading(false);
    }
  };

  const options = [
    { value: 'true', label: 'Publish' },
    { value: 'false', label: 'UnPublish' }
  ];

 

  if (loading) {
    return <Spinner />;
  }

  return (
    <>
      <div id="popup-message" className={`${styles.update_message} ${popupMessage.includes()}`}>
        {popupMessage && (
          <p>{popupMessage}</p>
        )}
      </div>
      <div className={`${styles.add_religion}`}>
        <header>
          <h2>Religion Management</h2>
        </header>
        <section className={`${styles.section}`}>
          <label htmlFor="title">Religion Title</label><br />
          <input type="text"
            id="title"
            value={title}
            onChange={handleTitleChange}
           
          />
          {errors.title && <p className={styles.error}>{errors.title}</p>}
          <br /><br />
          <label htmlFor="status">Religion status</label><br />
          <select value={selectedStatus} onChange={handleSelectChange}>
            <option value="">Select Status</option>
            {options.map((option, index) => (
              <option key={index} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
          {errors.selectedStatus && <p className={styles.error}>{errors.selectedStatus}</p>}
          <button onClick={handleSubmit}>{id ? 'Update Religion' : 'Add Religion'}</button>
        </section>
      </div>
    </>
  )
}

export default Religion
