import React, { useEffect, useState } from 'react';
import { FaTimes } from 'react-icons/fa';
import { FiChevronLeft, FiChevronRight, FiChevronsLeft, FiChevronsRight } from 'react-icons/fi';
import { LIVE_URL } from '../../../config/config';
import useAuthToken from '../../../hooks/useAuthToken';
import styles from "./css/Payment.module.scss";
import PaymentTable from './datatable/PaymentTable';

const Payment = () => {
  const { token, loading } = useAuthToken();

  // define state
  const [searchText, setSearchText] = useState('');
  const [payments, setPayments] = useState([]);
  const [currentPage, setCurrentPage] = useState(1); 
  const [totalPages, setTotalPages] = useState(1);

  useEffect(() => {
    const fetchPayment = async () => {
      try {
        const response = await fetch(`${LIVE_URL}/admin/list-user-report?page=${currentPage}`, {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });

        const data = await response.json();

        if (response.ok && data.data && Array.isArray(data.data.user)) {
          setPayments(data.data.user);
          setTotalPages(Math.ceil(data.data.pagination.totalCount  / data.data.pagination.defaultPageSize));
        } else {
          setPayments([]);
          setTotalPages(1);
        }
      } catch (error) {
        setPayments([]);
        setTotalPages(1);
      }
    };

    if (!loading && token) {
      fetchPayment();
    }
  }, [token, loading, currentPage]);

  //  // handle search click event
  //  const handleSearchClick = () => {
  //   document.getElementById('search').focus();
  // }

  // handle clear search event
  const handleClearSearch = () => {
    setSearchText('');
    document.getElementById('search').focus();
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handlePrevious = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNext = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  // Added function to go two pages back
  const handlePreviousTwo = () => {
    if (currentPage > 2) {
      setCurrentPage(currentPage - 2);
    } else if (currentPage === 2) {
      setCurrentPage(1);
    }
  };

  // Added function to go two pages forward
  const handleNextTwo = () => {
    if (currentPage < totalPages - 1) {
      setCurrentPage(currentPage + 2);
    } else if (currentPage === totalPages - 1) {
      setCurrentPage(totalPages);
    }
  };

  const getPageNumbers = () => {
    const pageNumbers = [];
    if (currentPage > 1) {
      pageNumbers.push(currentPage - 1);
    }
    pageNumbers.push(currentPage);
    if (currentPage < totalPages) {
      pageNumbers.push(currentPage + 1);
    }
    return pageNumbers;
  };
  return (
    <>
      <div className={`${styles.payment}`}>
        <header>
          <h2>Payment List Management</h2>
        </header>
        {/* entry section */}
        <section className={`${styles.section}`}>
          <div className={`${styles.user_entry}`}>
            <div className={`${styles.entries}`}>
              <label htmlFor="show">Show</label>
               <p>10</p>
              <label htmlFor="entries">entries</label>
            </div>
            <div className={`${styles.search}`}>
              <label htmlFor="search">Search: </label>
              <input
                type="text"
                id='search'
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
                // onClick={handleSearchClick}
              />
              {searchText && <FaTimes className={styles.closeIcon} onClick={handleClearSearch} />}
            </div>
          </div>

          {/* user information section */}
          <div className={`${styles.user_info}`}>
            <PaymentTable
              apiEndpoint={`${LIVE_URL}/admin/list-user-report?page=${currentPage}`}
              searchText={searchText}
              payments={payments}
            />
          </div>

          {/* pagination section */}
          <div className={`${styles.pagination}`}>
            <button onClick={handlePreviousTwo} disabled={currentPage === 1}>
              <FiChevronsLeft />
            </button>
            <button onClick={handlePrevious} disabled={currentPage === 1}><FiChevronLeft />
            </button>
            {getPageNumbers().map((page) => (
              <button
                key={page}
                onClick={() => handlePageChange(page)}
                className={currentPage === page ? styles.active : ''}
              >
                {page}
              </button>
            ))}
            <button onClick={handleNext} disabled={currentPage === totalPages}><FiChevronRight />
            </button>
            <button onClick={handleNextTwo} disabled={currentPage === totalPages || currentPage === totalPages - 1  }>
              <FiChevronsRight />
            </button>
          </div>
        </section>
      </div>
    </>
  )
}

export default Payment
