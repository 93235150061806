import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useAuthToken from '../../../../hooks/useAuthToken';
import styles from './css/PaymentTable.module.scss';

const PaymentTable = ({ apiEndpoint, rows, searchText, payments }) => {
    const { token, loading } = useAuthToken();
    const [products, setProducts] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchPayments = async () => {
            try {
                const response = await fetch(apiEndpoint, {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    },
                });

                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();

                setProducts(data.data.user);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        if (!loading && token) {
            fetchPayments();
        }
    }, [apiEndpoint, rows, token, loading]);

    // Filter products based on searchText
    const filteredPayments = payments.filter(payment =>
        payment.message.toLowerCase().includes(searchText.toLowerCase())
    );

    const columns = [
        { field: '', header: 'Sr No.', className: styles['column-id'], sortable: true },
        { field: '', header: 'Amount', className: styles['column-amount'], sortable: true },
        { field: '', header: 'Coin', className: styles['column-coin'], sortable: true },
        { field: '', header: 'User Name', className: styles['column-username'], sortable: true },
        { field: '', header: 'Transfer Details', className: styles['column-transfer_detail'], sortable: true },
        { field: '', header: 'Transfer Type', className: styles['column-transfer_type'], sortable: true },
        { field: '', header: 'User Mobile', className: styles['column-user_mobile'], sortable: true },
        { field: '', header: 'status', className: styles['column-status'], sortable: true },
        {
            field: '',
            header: 'Action',
            className: styles['column-action'],
            sortable: true
        },
    ];

    return (
        <>
            <div className={`${styles.main}`}>
                <DataTable
                    className={`${styles.datatable}`}
                    // value={filteredPayments}
                    rows={rows}
                    loading={loading}
                    emptyMessage={<div className={styles['no-data-message']}>No data to display.</div>}
                >
                    {columns.map((col, i) => (
                        <Column
                            key={i}
                            field={col.field}
                            header={col.header}
                            sortable={col.sortable}
                            headerClassName={`${styles['column-header-small']} ${col.className}`}
                            bodyClassName={col.className}
                            body={col.body}
                        />
                    ))}
                </DataTable>
            </div>
        </>
    )
}

export default PaymentTable;



