import React, { useEffect, useState } from 'react';
import { FaTimes } from 'react-icons/fa';
import { useLocation, useNavigate } from 'react-router-dom';
import { LIVE_URL } from '../../../config/config';
import useAuthToken from '../../../hooks/useAuthToken';
import styles from "./css/ListPage.module.scss";
import ProductTable from './datatable/ProductTable';

const ListPage = () => {
  const { token, loading } = useAuthToken();
  const navigate = useNavigate();
  const location = useLocation();

  // define state
  const [searchText, setSearchText] = useState('');
  const [totalCount, setTotalCount] = useState(0);
  const [pages, setPages] = useState([]);
  const [popupMessage, setPopupMessage] = useState('');

  useEffect(() => {
    const fetchPages = async () => {
      try {
        const response = await fetch(`${LIVE_URL}/admin/page/list?=${1}`, {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });

        const data = await response.json();

        if (response.ok && data.data && Array.isArray(data.data.getPage)) {
          setPages(data.data.getPage);
          setTotalCount(data.data.pagination?.totalCount || 0);
        } else {
          setPages([]);
          setTotalCount(0);
        }
      } catch (error) {
        setPages([]);
        setTotalCount(0);
      }
    };

    if (!loading && token) {
      fetchPages();
    }
  }, [token, loading]);

  useEffect(() => {
    if (location.search) {
      const params = new URLSearchParams(location.search);
      const message = params.get('message');
      if (message) {
        setPopupMessage(decodeURIComponent(message));
        setTimeout(() => {
          setPopupMessage('');
          navigate({ search: '' });
        }, 2000);
      }
    }
  }, [location.search, navigate]);

  // handle search click event
  const handleSearchClick = () => {
    document.getElementById('search').focus();
  }

  // handle clear search event
  const handleClearSearch = () => {
    setSearchText('');
    document.getElementById('search').focus();
  };

  return (
    <>
      <div className={`${styles.list_page}`}>
        {popupMessage && (
          <div className={`${styles.update_message}`}>
            <p>{popupMessage}</p>
          </div>
        )}
        <header>
          <h2>Page List Management</h2>
        </header>
        {/* entry section */}
        <section className={`${styles.section}`}>
          <div className={`${styles.user_entry}`}>
            {/* <div className={`${styles.entries}`}>
              <label htmlFor="show">Show</label>
               <p>10</p>
              <label htmlFor="entries">entries</label>
            </div> */}
            <div className={`${styles.search}`}>
              <label htmlFor="search">Search: </label>
              <input
                type="text"
                id='search'
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
                onClick={handleSearchClick}
              />
              {searchText && <FaTimes className={styles.closeIcon} onClick={handleClearSearch} />}
            </div>
          </div>

          {/* user information section */}
          <div className={`${styles.user_info}`}>
            <ProductTable
              apiEndpoint={`${LIVE_URL}/admin/page/list?page=${1}`}
              pages={pages}
              searchText={searchText}
              loading={loading}
              totalCount={totalCount}
            />
          </div>
        </section>
      </div>
    </>
  )
}

export default ListPage;
