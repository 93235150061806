import { format } from 'date-fns';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useAuthToken from '../../../../hooks/useAuthToken';
import styles from './css/ProductTable.module.scss';

const ProductTable = ({ apiEndpoint, rows, reports, setReports, currentPage, setCurrentPage, sortField, sortOrder, setSortField, setSortOrder }) => {
    const { token, loading } = useAuthToken();
    const [products, setProducts] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchReports = async () => {
            try {
                const response = await fetch(apiEndpoint, {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    },
                });

                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();
                setProducts(data.data.user);
                setReports(data.data.user);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        if (!loading && token) {
            fetchReports();
        }
    }, [apiEndpoint, rows, token, loading]);

    const columns = [
        { field: 'id', header: 'Sr No.', className: styles['column-id'], sortable: true },
        { field: 'user.firstname', header: 'User Name', className: styles['column-firstname'], sortable: true },
        { field: 'report_user.firstname', header: 'Report User', className: styles['column-report-user'], sortable: true },
        { field: 'message', header: 'Comment', className: styles['column-comment'], sortable: false },
        {
            field: 'created_at',
            header: 'Date',
            className: styles['column-date'],
            body: (rowData) => format(new Date(rowData.created_at), 'dd-MM-yyyy'),
            sortable: true
        },
    ];

    const handlePageChange = (event) => {
        setCurrentPage(event.page + 1);
    };

    const handleSort = (event) => {
        setSortField(event.sortField);
        setSortOrder(event.sortOrder === 1 ? 'ASC' : 'DESC');
        setCurrentPage(1);
    };

    return (
        <>
            <div className={`${styles.main}`}>
                <DataTable
                    className={`${styles.datatable}`}
                    value={reports}
                    rows={rows}
                    loading={loading}
                    first={(currentPage - 1) * rows}
                    onPage={handlePageChange}
                    totalRecords={reports.length}
                    sortField={sortField}
                    sortOrder={sortOrder === 'ASC' ? 1 : -1}
                    onSort={handleSort}
                    emptyMessage={<div className={styles['no-data-message']}>No data to display.</div>}
                >
                    {columns.map((col, i) => (
                        <Column
                            key={i}
                            field={col.field}
                            header={col.header}
                            sortable={col.sortable}
                            headerClassName={`${styles['column-header-small']} ${col.className}`}
                            bodyClassName={col.className}
                            body={col.body}
                            handleSort={handleSort}
                        />
                    ))}
                </DataTable>
            </div>
        </>
    )
}

export default ProductTable;



