import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { LIVE_URL } from "../../../config/config";
import useAuthToken from '../../../hooks/useAuthToken';
import Spinner from '../spinner/Spinner';
import styles from "./css/AddRelation.module.scss";

const AddRelation = () => {
  const { token } = useAuthToken();
  const { id } = useParams();
  const navigate = useNavigate();

  const [relationTitle, setRelationTitle] = useState('');
  const [relationSubTitle, setRelationSubTitle] = useState('');
  const [selectedStatus, setSelectedStatus] = useState('');
  const [popupMessage, setPopupMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});

  // Function to reset form fields
  const resetForm = () => {
    setRelationTitle('');
    setRelationSubTitle('');
    setSelectedStatus('');
    setPopupMessage('');
    setErrors({});
  };

  useEffect(() => {
    if (id) {
      const fetchRelationGoalData = async () => {
        try {
          setLoading(true);
          const response = await fetch(`${LIVE_URL}/admin/relation-goal/${id}`, {
            headers: {
              'Authorization': `Bearer ${token}`,
            },
          });
          const data = await response.json();
          if (response.ok) {
            const relationGoalData = data.data;
            setRelationTitle(relationGoalData.title);
            setRelationSubTitle(relationGoalData.sub_title);
            setSelectedStatus(relationGoalData.status ? 'true' : 'false');
          }
        } catch (error) {
          console.error('Error fetching relation-goal data:', error);
        } finally {
          setLoading(false);
        }
      };
      fetchRelationGoalData();
    } else {
      resetForm();
      setLoading(false);
    }
  }, [id, token]);

  useEffect(() => {
    let timer;
    if (popupMessage) {
      const popupElement = document.getElementById('popup-message');
      if (popupElement) {
        popupElement.style.opacity = '1';
      }
      timer = setTimeout(() => {
        if (popupElement) {
          popupElement.style.opacity = '0';
        }
        setTimeout(() => {
          setPopupMessage('');
        }, 500);
      }, 2000);
    }
    return () => clearTimeout(timer);
  }, [popupMessage]);



  const handleTitleChange = (event) => {
    setRelationTitle(event.target.value);
    setErrors((prevErrors) => ({ ...prevErrors, relationTitle: '' }));

  };

  const handleSubTitleChange = (event) => {
    setRelationSubTitle(event.target.value);
    setErrors((prevErrors) => ({ ...prevErrors, relationSubTitle: '' }));
  };

  const handleSelectChange = (event) => {
    setSelectedStatus(event.target.value);
    setErrors((prevErrors) => ({ ...prevErrors, selectedStatus: '' }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    const newErrors = {};
    if (!relationTitle) newErrors.relationTitle = "🖋️ Don't Forget the Title!";
    if (!relationSubTitle) newErrors.relationSubTitle = "🖋️ Don't Forget the Subitle!";
    if (!selectedStatus) newErrors.selectedStatus = '❗️Status field cannot be blank.';
    setErrors(newErrors);

    if (Object.keys(newErrors).length > 0) {
      setPopupMessage('Please fill in all required fields');
      setLoading(false);
      return;
    }

    const relationGoalData = {
      title: relationTitle,
      sub_title: relationSubTitle,
      status: selectedStatus === 'true',
    };

    try {
      const url = id
        ? `${LIVE_URL}/admin/relation-goal/update`
        : `${LIVE_URL}/admin/relation-goal/create`;

      const body = id ? { id, ...relationGoalData } : relationGoalData;

      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      });

      if (response.ok) {
         const successMessage = id ? 'Relation updated successfully.' : 'Relation saved successfully.';
          navigate(`/home/list-relation?message=${encodeURIComponent(successMessage)}`);
      } else {
        setPopupMessage(`Failed to save relation. Status: ${response.status}`);
      }
    } catch (error) {
      setPopupMessage('Error saving relation.');
    }finally{
      setLoading(false);
    }
  };

  const options = [
    { value: 'true', label: 'Publish' },
    { value: 'false', label: 'UnPublish' }
  ];

  if(loading){
    return <Spinner/>;
  }

  return (
    <>
      <div id="popup-message" className={`${styles.update_message} ${popupMessage.includes('success')}`}>
        {popupMessage && (
          <p>{popupMessage}</p>
        )}
      </div>
      <div className={`${styles.add_relation}`}>
        <header>
          <h2>Relation Goal Management</h2>
        </header>
        <section className={`${styles.section}`}>

          <label htmlFor="title">Relation Goal Title</label><br />
          <input type="text"
            id="relationTitle"
            value={relationTitle}
            onChange={handleTitleChange}
          />
          {errors.relationTitle && <p className={styles.error}>{errors.relationTitle}</p>}
          <br /><br />
          <label htmlFor="title">Relation Goal Subtitle</label><br />
          <input type="text"
            id="relationSubTitle"
            value={relationSubTitle}
            onChange={handleSubTitleChange}
          />
          {errors.relationSubTitle && <p className={styles.error}>{errors.relationSubTitle}</p>}
          <br /><br />
          <label htmlFor="status">Relation Goal status</label><br />
          <select value={selectedStatus} onChange={handleSelectChange}>
            <option value="">Select Status</option>
            {options.map((option, index) => (
              <option key={index} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
          {errors.selectedStatus && <p className={styles.error}>{errors.selectedStatus}</p>}
          <button onClick={handleSubmit}>{id ? 'Update Relation Goal' : 'Add Relation Goal'}</button>
        </section>
      </div>
    </>
  )
}

export default AddRelation;
