import React from 'react';
import ReactModal from 'react-modal';
import styles from "./css/CustomModel.module.scss";

const CustomModal = ({ isOpen, onRequestClose, onConfirm }) => {
  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      className={styles.modal}
      overlayClassName={styles.overlay}
    >
      <div className={`${styles.modalContent}`}>
        <h2>Confirm Delete</h2>
        <p>Are you sure you want to delete this interest?</p>
        <div className={`${styles.modalActions}`}>
          <button onClick={onRequestClose}>Cancel</button>
          <button onClick={onConfirm}>Delete</button>
        </div>
      </div>
    </ReactModal>
  );
};

export default CustomModal;
