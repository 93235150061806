import { format } from 'date-fns';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import React, { useEffect, useState } from 'react';
import { BiSolidTrashAlt } from "react-icons/bi";
import { FaUserCircle } from "react-icons/fa";
import { useNavigate } from 'react-router-dom';
import { LIVE_URL } from '../../../../config/config';
import useAuthToken from '../../../../hooks/useAuthToken';
import CustomModel from "../../customModel/CustomModel";
import styles from './css/UserTable.module.scss';

const UserTable = ({ apiEndpoint, rows, users, setUsers, currentPage, setCurrentPage, sortField, sortOrder, setSortField, setSortOrder }) => {
    const { token, loading } = useAuthToken();
    const navigate = useNavigate();
    const [products, setProducts] = useState([]);
    const [popupMessage, setPopupMessage] = useState('');
    const [modalOpen, setModalOpen] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);

    useEffect(() => {
        const fetchUsers = async () => {
            try {
                const response = await fetch(`${apiEndpoint}?page=${currentPage}&pageSize=${rows}&sort_by=${sortField}&order_by=${sortOrder}`, {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    },
                });

                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();
                setProducts(data.data.user);
                setUsers(data.data.user);

            } catch (error) {
                console.error('Error fetching data:', error);
                setProducts([]);
                setUsers([]);
            }
        };

        if (!loading && token) {
            fetchUsers();
        }
    }, [apiEndpoint, rows, token, loading, currentPage, sortField, sortOrder, setUsers]);

    useEffect(() => {
        let timer;
        if (popupMessage) {
            const popupElement = document.getElementById('popup-message');
            if (popupElement) {
                popupElement.style.opacity = '1';
            }
            timer = setTimeout(() => {
                if (popupElement) {
                    popupElement.style.opacity = '0';
                }
                setTimeout(() => {
                    setPopupMessage('');
                }, 500);
            }, 2000);
        }
        return () => clearTimeout(timer);
    }, [popupMessage]);

    // handle show the profile
    const handleEdit = (id) => {
        navigate(`/home/user-info/${id}`);
    };

    const handleDeleteClick = (email) => {
        setSelectedUser(email);
        setModalOpen(true);
    };

    const handleDeleteConfirm = async () => {
        setModalOpen(false);
        if (selectedUser !== null && selectedUser.email) {
            try {
                const response = await fetch(`${LIVE_URL}/user/delete`, {
                    method: 'POST',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ email: selectedUser.email }),
                });

                if (response.status === 200) {
                    setUsers(prevUsers => prevUsers.filter(user => user.email !== selectedUser.email));
                    setPopupMessage('User deleted successfully.');
                } else {
                    setPopupMessage(`Failed to delete user. Status: ${response.status}`);
                }
            } catch (error) {
                setPopupMessage('Error deleting user.');
            }
        }
    };

    const columns = [
        { field: 'id', header: 'Sr No.', className: styles['column-id'], sortable: true },
        { field: 'firstname', header: 'Name', className: styles['column-name'], sortable: true ,
            body: (rowData) => rowData.firstname || '---'
        },
        { field: 'mobile', header: 'Mobile', className: styles['column-mobile'], sortable: false, 
            body: (rowData) => rowData.mobile || '---'
        },
        { field: 'email', header: 'Email', className: styles['column-email'], sortable: true,
            body: (rowData) => rowData.email || '---'
         },
        { field: 'country_code', header: 'Country Code', className: styles['column-email'], sortable: true,
            body: (rowData) => rowData.country_code || '---'
        },
        {
            field: 'created_at',
            header: 'Create Date',
            className: styles['column-create-date'],
            body: (rowData) => format(new Date(rowData.created_at), 'dd-MM-yyyy'),
            sortable: true
        },
        { field: 'is_setup_completed', header: 'Status', className: styles['column-status'], sortable: true },
        {
            field: 'action',
            header: 'View Profile',
            className: styles['column-action'],
            body: (rowData) => (
                <div className={styles['action-icons']}>
                    <FaUserCircle className={styles['user-icon']} onClick={() => handleEdit(rowData.id)} />
                </div>
            ),
        },
        {
            field: 'delete',
            header: 'Delete User',
            className: styles['column-action'],
            body: (rowData) => (
                <div className={styles['action-icons']}>
                    <BiSolidTrashAlt className={styles['trash-icon']} onClick={() => handleDeleteClick(rowData)} />
                </div>
            ),
        },
    ];

    const handlePageChange = (event) => {
        setCurrentPage(event.page + 1);
    };

    const handleSort = (event) => {
        setSortField(event.sortField);
        setSortOrder(event.sortOrder === 1 ? 'ASC' : 'DESC');
    };

    return (
        <>
            <div id="popup-message" className={`${styles.delete_message} ${popupMessage.includes()}`}>
                {popupMessage && (
                    <p>{popupMessage}</p>
                )}
            </div>
            <div className={`${styles.main}`}>
                <DataTable
                    className={`${styles.datatable}`}
                    value={users}
                    rows={rows}
                    loading={loading}
                    first={(currentPage - 1) * rows}
                    onPage={handlePageChange}
                    totalRecords={users.length}
                    sortField={sortField}
                    sortOrder={sortOrder === 'ASC' ? 1 : -1}
                    onSort={handleSort}
                    emptyMessage={<div className={styles['no-data-message']}>No data to display.</div>}
                    dataKey="id"
                >
                    {columns.map((col, i) => (
                        <Column
                            key={`${col.field}-${i}`}
                            field={col.field}
                            header={col.header}
                            sortable={col.sortable}
                            headerClassName={`${styles['column-header-small']} ${col.className}`}
                            bodyClassName={col.className}
                            body={col.body}
                            handleSort={handleSort}
                        />
                    ))}
                </DataTable>
            </div>
            <CustomModel
                isOpen={modalOpen}
                onRequestClose={() => setModalOpen(false)}
                onConfirm={handleDeleteConfirm}
            />
        </>
    )
}

export default UserTable;