import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { MdOutlineEmail } from "react-icons/md";
import { useNavigate } from 'react-router-dom';
import { LIVE_URL } from '../../config/config';
import styles from "./css/ForgoPassword.module.scss";

const ForgotPassword = () => {
    // State variables to manage email input and error messages
    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState('');
    const [popupMessage, setPopupMessage] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();

     // Effect to handle popup message visibility with fade-out animation
    useEffect(() => {
        let timer;
        if (popupMessage) {
            const popupElement = document.getElementById('popup-message');
            if (popupElement) {
                popupElement.style.opacity = '1';
            }
            timer = setTimeout(() => {
                if (popupElement) {
                    popupElement.style.opacity = '0';
                }
                setTimeout(() => {
                    setPopupMessage('');
                }, 500);
            }, 2000);
        }
        return () => clearTimeout(timer);
    }, [popupMessage]);

     // Function to validate email input
    const validateEmail = () => {
        if (!email) {
            setEmailError('Email is required');
            return false;
        } else if (email.startsWith(' ') || email.endsWith(' ')) {
            setEmailError('Email cannot have leading or trailing spaces');
            return false;
        } else if (!/^[\w-.]+@([\w-]+\.)+[a-zA-Z]{2,7}$/.test(email)) {
            setEmailError('Invalid email format');
            return false;
        } else {
            setEmailError('');
            return true;
        }
    };

     // Function to handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!validateEmail()) {
            setPopupMessage('Email Cannot Be Blank');
            return;
        }

         // Store email in local storage when the form is submitted
         localStorage.setItem('email', email);

        try {
            await axios.post(`${LIVE_URL}/admin/forgot-password`, { email }, {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            });
            setPopupMessage('Password reset link has been sent to your email.');
            navigate('/verify-otp')
        } catch (error) {
            setPopupMessage(error.response?.data?.message || 'An error occurred');
        }
    };

    return (
        <div className={`${styles.wrapper}`}>
            {/* Popup message*/}
            <div id="popup-message" className={`${styles.forgot_error_message} ${popupMessage.includes('success') ? styles.success : styles.error}`}>
                {popupMessage && <p>{popupMessage}</p>}
            </div>
             {/* Forgot Password Form */}
            <div className={`${styles.forgotPassword}`}>
                <h2>Forgot Password</h2>
                <p>Enter Your Email Address To Reset Your Password</p>
                <form onSubmit={handleSubmit}>
                    {/* Email Input Field with Icon */}
                    <div className={`${styles.input_icon}`}>
                        <MdOutlineEmail />
                        <input
                            type="email"
                            placeholder='Enter Email Here'
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            className={emailError ? styles.error_input : ''}
                        />
                        {/* {emailError && <span className={styles.error_message}>{emailError}</span>} */}
                    </div>
                    {error && <div className={`${styles.error_messages}`}>{error}</div>}
                    <button type="submit">Get OTP</button>
                </form>
            </div>
        </div>
    );
};

export default ForgotPassword;
