import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import React, { useEffect, useState } from 'react';
import { BiSolidTrashAlt } from "react-icons/bi";
import { HiMiniPencilSquare } from "react-icons/hi2";
import { useNavigate } from 'react-router-dom';
import { LIVE_URL } from '../../../../config/config';
import useAuthToken from '../../../../hooks/useAuthToken';
import CustomModel from '../../customModel/CustomModel';
import styles from './css/ProductTable.module.scss';

const ProductTable = ({ relations, rows, setRelations, currentPage, setCurrentPage, sortField, sortOrder, setSortField, setSortOrder }) => {
    const { token } = useAuthToken();
    const navigate = useNavigate();
    const [popupMessage, setPopupMessage] = useState('');
    const [modalOpen, setModalOpen] = useState(false);
    const [selectedRelationId, setSelectedRelationId] = useState(null);

    useEffect(() => {
        let timer;
        if (popupMessage) {
            const popupElement = document.getElementById('popup-message');
            if (popupElement) {
                popupElement.style.opacity = '1';
            }
            timer = setTimeout(() => {
                if (popupElement) {
                    popupElement.style.opacity = '0';
                }
                setTimeout(() => {
                    setPopupMessage('');
                }, 500);
            }, 2000);
        }
        return () => clearTimeout(timer);
    }, [popupMessage]);

    const handleEdit = (id) => {
        navigate(`/home/add-relation/${id}`);
    };

    const handleDeleteClick = (id) => {
        setSelectedRelationId(id);
        setModalOpen(true);
    };

    const handleDeleteConfirm = async () => {
        setModalOpen(false);
        if (selectedRelationId !== null) {
            try {
                const response = await fetch(`${LIVE_URL}/admin/relation-goal/delete/${selectedRelationId}`, {
                    method: 'POST',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                });

                if (response.status === 200) {
                    setRelations(prevRelations => prevRelations.filter(relation => relation.id !== selectedRelationId));
                    setPopupMessage(`Relation deleted successfully`);
                } else {
                    setPopupMessage(`Failed to delete Relation. Status: ${response.status}`);
                }
            } catch (error) {
                setPopupMessage('Error deleting Relation.');
            }
        }
    };

    const columns = [
        { field: 'id', header: 'Sr No.', className: styles['column-id'], sortable: true },
        {
            field: 'title',
            header: 'Relation Goal Title',
            className: styles['column-title'],
            body: (rowData) => (
                <div className={styles['title-text']}>
                    {rowData.title}
                </div>
            ),
            sortable: true,
        },
        {
            field: 'sub_title',
            header: 'Relation Goal Subtitle',
            className: styles['column-subtitle'],
            body: (rowData) => (
                <div className={styles['subtitle-text']}>
                    {rowData.sub_title}
                </div>
            ),
            sortable: false
        },
        {
            field: 'status',
            header: 'Relation Goal Status',
            className: styles['column-status'],
            body: (rowData) => (
                <span className={rowData.status ? styles['status-published'] : styles['status-unpublished']}>
                    {rowData.status ? 'Publish' : 'Unpublish'}
                </span>
            ),
            sortable: true
        }, {
            field: 'action',
            header: 'Action',
            className: styles['column-action'],
            body: (rowData) => (
                <div className={styles['action-icons']}>
                    <HiMiniPencilSquare className={styles['pencil-icon']} onClick={() => handleEdit(rowData.id)} />
                    <BiSolidTrashAlt className={styles['trash-icon']} onClick={() => handleDeleteClick(rowData.id)} />                </div>
            ),
            sortable: false
        },
    ];

    const handlePageChange = (event) => {
        setCurrentPage(event.page + 1);
    };

    const handleSort = (event) => {
        setSortField(event.sortField);
        setSortOrder(event.sortOrder === 1 ? 'ASC' : 'DESC');
    };


    return (
        <>
            <div id="popup-message" className={`${styles.delete_message} ${popupMessage.includes('success') ? styles.success : styles.error}`}>
                {popupMessage && (
                    <p>{popupMessage}</p>
                )}
            </div>
            <div className={`${styles.main}`}>
                <DataTable
                    className={`${styles.datatable}`}
                    value={relations}
                    rows={rows}
                    first={(currentPage - 1) * rows}
                    onPage={handlePageChange}
                    totalRecords={relations.length}
                    sortField={sortField}
                    sortOrder={sortOrder === 'ASC' ? 1 : -1}
                    onSort={handleSort}
                    emptyMessage={<div className={styles['no-data-message']}>No data to display.</div>}
                >
                    {columns.map((col, i) => (
                        <Column
                            key={i}
                            field={col.field}
                            header={col.header}
                            sortable={col.sortable}
                            headerClassName={`${styles['column-header-small']} ${col.className}`}
                            bodyClassName={col.className}
                            body={col.body}
                            handleSort={handleSort}
                        />
                    ))}
                </DataTable>
            </div>
            <CustomModel
                isOpen={modalOpen}
                onRequestClose={() => setModalOpen(false)}
                onConfirm={handleDeleteConfirm}
            />
        </>
    )
}

export default ProductTable;



