import React, { useEffect, useRef, useState } from 'react';
import { FaBars, FaUserCircle } from "react-icons/fa";
// import { IoMdSettings } from "react-icons/io";
import { MdOutlineCloseFullscreen } from "react-icons/md";
import { RiLogoutCircleRLine } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import styles from './css/Navbar.module.scss';

const Navbar = ({ toggleSidebar }) => {
    // useState hook to manage the dropdown menu's open/closed state
    const [dropdownOpen, setDropdownOpen] = useState(false);

    // useRef to keep track of the dropdown element for detecting clicks outside
    const dropdownRef = useRef(null);

    // useNavigate hook for programmatic navigation
    const navigate = useNavigate();

    // Function to toggle fullscreen mode
    const toggleFullscreen = () => {
        if (!document.fullscreenElement) {
            document.documentElement.requestFullscreen();
        } else if (document.exitFullscreen) {
            document.exitFullscreen();
        }
    };

    // Function to toggle dropdown menu
    const toggleDropdown = () => {
        setDropdownOpen(!dropdownOpen);
    }

    // Function to handle user logout
    const handleLogout = () => {
        localStorage.removeItem('token');
        localStorage.removeItem('email');
        localStorage.removeItem('homeActive');
        navigate('/admin-login');
    }

    //  // Function to navigate
    // const handleNavigation = (path) => {
    //     setDropdownOpen(false);
    //     navigate(path);
    // };

    // useEffect to add an event listener for clicks outside the dropdown menu
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setDropdownOpen(false);
            }
        };

        if (dropdownOpen) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [dropdownOpen]);

    return (
        <>
            {/* Navbar container */}
            <div className={`${styles.navbar}`}>
                <div className={`${styles.user_info}`}>
                    {/* <IoMdMoon onClick={toggleTheme} /> */}
                    {/* Fullscreen toggle icon */}
                    <MdOutlineCloseFullscreen onClick={toggleFullscreen} />
                    {/* User profile section with dropdown menu */}
                    <div className={styles.profile} ref={dropdownRef}>
                        <FaUserCircle onClick={toggleDropdown} />
                        <FaBars className={styles.barIcon} onClick={toggleSidebar} />
                        {/* Dropdown menu */}
                        {dropdownOpen && (
                            <div className={styles.dropdown_menu} >
                                {/* <div className={styles.dropdown_item} onClick={() => handleNavigation('/home/account')}>
                                    <FaUserCircle /> Account
                                </div> */}
                                {/* <div className={styles.dropdown_item} onClick={() => handleNavigation('/home/settings')}>
                                    <IoMdSettings /> Settings
                                </div> */}
                                <div className={styles.dropdown_item} onClick={handleLogout}>
                                    <RiLogoutCircleRLine /> Log out
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </>
    )
}

export default Navbar
