import React from 'react';
import styles from "./css/Account.module.scss";

const Account = () => {
    return (
        <>
            <div className={`${styles.account}`}>
                <h2>Profile Management</h2>
                <div className={`${styles.form}`}>
                    <p>Username</p>
                    <input type="text" placeholder="Username" />
                    <p>Password</p>
                    <input type="password" placeholder="Password"/>
                    <br />
                    <button>Edit Profile</button>
                </div>
            </div>
        </>
    )
}

export default Account
