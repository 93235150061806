import { useEffect, useState } from 'react';
import { AiFillHome } from "react-icons/ai";
import { BsFillCameraReelsFill } from "react-icons/bs";
import { FaAngleDown, FaAngleRight } from "react-icons/fa";
import { FaToiletPaper } from "react-icons/fa6";
import { ImDelicious } from "react-icons/im";
import { IoAperture, IoDiamond, IoLanguage } from "react-icons/io5";
import { MdOutlineSpeakerNotes } from "react-icons/md";
import { RiGroup3Fill } from "react-icons/ri";
import { TbMoneybag, TbShieldFilled } from "react-icons/tb";
import { Link } from 'react-router-dom';
import logoImage from "../assets/let's meet sidebar logo.png";
import styles from './css/Sidebar.module.scss';

const Sidebar = ({ isSidebarOpen, toggleSidebar }) => {
    // State to manage the active submenu and active submenu item
    const [activeSubMenu, setActiveSubMenu] = useState(null);
    const [activeSubMenuItem, setActiveSubMenuItem] = useState(null);

    // Retrieve the "homeActive" value from localStorage
    const getHomeActive = localStorage.getItem('homeActive');

    // Effect to initialize the active submenu and submenu item based on localStorage values
    useEffect(() => {
        const activeMenu = localStorage.getItem('activeSubMenu');
        const homeActive = localStorage.getItem('homeActive');
        const activeSubItem = localStorage.getItem('activeSubMenuItem');

        if (homeActive === 'true' || !activeMenu) {
            setActiveSubMenu(null);
        } else if (activeMenu) {
            setActiveSubMenu(activeMenu);
        }

        if (activeSubItem) {
            setActiveSubMenuItem(activeSubItem);
        }
    }, [setActiveSubMenuItem]);

    // Function to handle submenu click and set active submenu
    const handleSubMenuClick = (subMenu) => {
        localStorage.setItem('homeActive', 'false');

        // Toggle the active submenu
        if (activeSubMenu === subMenu) {
            setActiveSubMenu(null);
            localStorage.removeItem('activeSubMenu');
        } else {
            setActiveSubMenu(subMenu);
            localStorage.setItem('activeSubMenu', subMenu);
        }
        // Reset the active submenu item
        setActiveSubMenuItem(null);
        localStorage.removeItem('activeSubMenuItem');
    };

    // Function to handle submenu item click
    const handleSubMenuItemClick = (subMenuItem) => {
        setActiveSubMenuItem(subMenuItem);
        localStorage.setItem('activeSubMenuItem', subMenuItem);
    };

    // Function to handle menu click without changing submenu item
    const handleWithoutClick = (link) => {
        localStorage.setItem('homeActive', 'false');
        setActiveSubMenu(link);
        localStorage.setItem('activeSubMenu', link);
    };

    return (
        <>
            <div className={`${styles.sidebar} sidebar ${isSidebarOpen}`} >
                <div className={`${styles.logo} logo`} >
                    <img src={logoImage} alt="" />
                </div>
                <div className={styles.category_list}>
                    <ul>
                        {/* Dashboard Menu Item */}
                        <div className={`${styles.sidebar_links} ${activeSubMenu === 'dashboard' ? styles.interest_clicked : ''} ${getHomeActive === 'true' ? styles.interest_clicked : ''}`} >
                            <li onClick={() => handleWithoutClick('dashboard')}>
                                <div className={styles.icon_and_link}>
                                    <AiFillHome className={`${getHomeActive === 'true' ? styles.interest_clicked : ''}`} />
                                    <Link to="dashboard">Dashboard</Link>
                                </div>
                            </li>
                        </div>
                        {/* User Menu Item */}
                        <div className={`${styles.sidebar_links} ${activeSubMenu === 'user' ? styles.interest_clicked : ''}`}>
                            <li onClick={() => handleWithoutClick('user')}>
                                <div className={styles.icon_and_link}>
                                    <RiGroup3Fill />
                                    <Link to="user">User List</Link>
                                </div>
                            </li>
                        </div>
                        {/* Interest Submenu */}
                        <div className={`${styles.sidebar_links} ${activeSubMenu === 'interest' ? styles.submenu_clicked : ''}`} >
                            <li onClick={() => handleSubMenuClick('interest')}>
                                <div className={styles.icon_and_link}>
                                    <BsFillCameraReelsFill />
                                    <span>Interest</span>
                                </div>
                                {activeSubMenu === 'interest' ? <FaAngleDown className={styles.chevron_down} /> : <FaAngleRight className={styles.chevron_right} />}
                            </li>
                            {activeSubMenu === 'interest' && (
                                <ul className={styles.submenu}>
                                    <li className={`${activeSubMenuItem === 'add-interest' ? styles.active : ''}`} onClick={() => handleSubMenuItemClick('add-interest')}>
                                        <Link to="add-interest">- Add Interest</Link>
                                    </li>
                                    <li className={`${activeSubMenuItem === 'list-interest' ? styles.active : ''}`} onClick={() => handleSubMenuItemClick('list-interest')}>
                                        <Link to="list-interest">- List Interest</Link>
                                    </li>
                                </ul>
                            )}
                        </div>
                        {/* Language Submenu */}
                        <div className={`${styles.sidebar_links} ${activeSubMenu === 'language' ? styles.submenu_clicked : ''}`}>
                            <li onClick={() => handleSubMenuClick('language')}>
                                <div className={styles.icon_and_link}>
                                    <IoLanguage />
                                    <span>Language</span>
                                </div>
                                {activeSubMenu === 'language' ? <FaAngleDown className={styles.chevron_down} /> : <FaAngleRight className={styles.chevron_right} />}
                            </li>
                            {activeSubMenu === 'language' && (
                                <ul className={styles.submenu}>
                                    <li className={`${activeSubMenuItem === 'add-language' ? styles.active : ''}`} onClick={() => handleSubMenuItemClick('add-language')}>
                                        <Link to="add-language">- Add Language</Link>
                                    </li>
                                    <li className={`${activeSubMenuItem === 'list-language' ? styles.active : ''}`} onClick={() => handleSubMenuItemClick('list-language')}>
                                        <Link to="list-language">- List Language</Link>
                                    </li>
                                </ul>
                            )}
                        </div>
                        {/* Religion Submenu */}
                        <div className={`${styles.sidebar_links} ${activeSubMenu === 'religion' ? styles.submenu_clicked : ''}`}>
                            <li onClick={() => handleSubMenuClick('religion')}>
                                <div className={styles.icon_and_link}>
                                    <IoAperture />
                                    <span>Religion</span>
                                </div>
                                {activeSubMenu === 'religion' ? <FaAngleDown className={styles.chevron_down} /> : <FaAngleRight className={styles.chevron_right} />}
                            </li>
                            {activeSubMenu === 'religion' && (
                                <ul className={styles.submenu}>
                                    <li className={`${activeSubMenuItem === 'add-religion' ? styles.active : ''}`} onClick={() => handleSubMenuItemClick('add-religion')}>
                                        <Link to="add-religion">- Add Religion</Link>
                                    </li>
                                    <li className={`${activeSubMenuItem === 'list-religion' ? styles.active : ''}`} onClick={() => handleSubMenuItemClick('list-religion')}>
                                        <Link to="list-religion">- List Religion</Link>
                                    </li>
                                </ul>
                            )}
                        </div>
                        {/* Relation Goal Submenu */}
                        <div className={`${styles.sidebar_links} ${activeSubMenu === 'relation' ? styles.submenu_clicked : ''}`}>
                            <li onClick={() => handleSubMenuClick('relation')}>
                                <div className={styles.icon_and_link}>
                                    <IoDiamond />
                                    <span>Relation Goal</span>
                                </div>
                                {activeSubMenu === 'relation' ? <FaAngleDown className={styles.chevron_down} /> : <FaAngleRight className={styles.chevron_right} />}
                            </li>
                            {activeSubMenu === 'relation' && (
                                <ul className={styles.submenu}>
                                    <li className={`${activeSubMenuItem === 'add-relation' ? styles.active : ''}`} onClick={() => handleSubMenuItemClick('add-relation')}>
                                        <Link to="add-relation">- Add Relation Goal</Link>
                                    </li>
                                    <li className={`${activeSubMenuItem === 'list-relation' ? styles.active : ''}`} onClick={() => handleSubMenuItemClick('list-relation')}>
                                        <Link to="list-relation">- List Relation Goal</Link>
                                    </li>
                                </ul>
                            )}
                        </div>
                        {/* FAQ Submenu */}
                        <div className={`${styles.sidebar_links} ${activeSubMenu === 'faq' ? styles.submenu_clicked : ''}`}>
                            <li onClick={() => handleSubMenuClick('faq')}>
                                <div className={styles.icon_and_link}>
                                    <MdOutlineSpeakerNotes />
                                    <span>FAQ</span>
                                </div>
                                {activeSubMenu === 'faq' ? <FaAngleDown className={styles.chevron_down} /> : <FaAngleRight className={styles.chevron_right} />}
                            </li>
                            {activeSubMenu === 'faq' && (
                                <ul className={styles.submenu}>
                                    <li className={`${activeSubMenuItem === 'add-faq' ? styles.active : ''}`} onClick={() => handleSubMenuItemClick('add-faq')}>
                                        <Link to="add-faq">- Add FAQ</Link>
                                    </li>
                                    <li className={`${activeSubMenuItem === 'list-faq' ? styles.active : ''}`} onClick={() => handleSubMenuItemClick('list-faq')}>
                                        <Link to="list-faq">- List FAQ</Link>
                                    </li>
                                </ul>
                            )}
                        </div>
                         {/* Plan Submenu */}
                        <div className={`${styles.sidebar_links} ${activeSubMenu === 'plan' ? styles.submenu_clicked : ''}`}>
                            <li onClick={() => handleSubMenuClick('plan')}>
                                <div className={styles.icon_and_link}>
                                    <ImDelicious />
                                    <span>Plan</span>
                                </div>
                                {activeSubMenu === 'plan' ? <FaAngleDown className={styles.chevron_down} /> : <FaAngleRight className={styles.chevron_right} />}
                            </li>
                            {activeSubMenu === 'plan' && (
                                <ul className={styles.submenu}>
                                    {/* <li className={styles.active_submenu}>
                                        <Link to="add-plan">- Add Plan</Link>
                                    </li> */}
                                    <li className={`${activeSubMenuItem === 'list-plan' ? styles.active : ''}`} onClick={() => handleSubMenuItemClick('list-plan')}>
                                        <Link to="list-plan">- List Plan</Link>
                                    </li>
                                </ul>
                            )}
                        </div>
                        {/* Payment Menu */}
                        <div className={`${styles.sidebar_links} ${activeSubMenu === 'payment' ? styles.interest_clicked : ''}`}>
                            <li onClick={() => handleWithoutClick('payment')}>
                                <div className={styles.icon_and_link}>
                                    <TbMoneybag />
                                    <Link to="payment">Payment List</Link>
                                </div>
                            </li>
                        </div>
                        {/* Report Menu */}
                        <div className={`${styles.sidebar_links} ${activeSubMenu === 'report' ? styles.interest_clicked : ''}`}>
                            <li onClick={() => handleWithoutClick('report')}>
                                <div className={styles.icon_and_link}>
                                    <TbShieldFilled />
                                    <Link to="report">Report List</Link>
                                </div>
                            </li>
                        </div>
                         {/* Page Menu */}
                        <div className={`${styles.sidebar_links} ${activeSubMenu === 'page' ? styles.submenu_clicked : ''}`} >
                            <li onClick={() => handleSubMenuClick('page')}>
                                <div className={styles.icon_and_link}>
                                    <FaToiletPaper />
                                    <span>Page</span>
                                </div>
                                {activeSubMenu === 'page' ? <FaAngleDown className={styles.chevron_down} /> : <FaAngleRight className={styles.chevron_right} />}
                            </li>
                            {activeSubMenu === 'page' && (
                                <ul className={styles.submenu}>
                                    {/* <li className={styles.active_submenu}>
                                        <Link to="add-page">- Add Page</Link>
                                    </li> */}
                                    <li className={`${activeSubMenuItem === 'list-page' ? styles.active : ''}`} onClick={() => handleSubMenuItemClick('list-page')}>
                                        <Link to="list-page">- List page</Link>
                                    </li>
                                </ul>
                            )}
                        </div>
                    </ul>
                </div>
            </div>
        </>
    )
}

export default Sidebar;
