// import { saveAs } from 'file-saver';
import React, { useEffect, useState } from 'react';
import { FaTimes } from 'react-icons/fa';
import { FiChevronLeft, FiChevronRight, FiChevronsLeft, FiChevronsRight } from 'react-icons/fi';
import { useLocation, useNavigate } from 'react-router-dom';
// import * as XLSX from 'xlsx';
import { LIVE_URL } from '../../../config/config';
import useAuthToken from '../../../hooks/useAuthToken';
import styles from "./css/ListReligion.module.scss";
import ProductTable from './datatable/ProductTable';

const ListReligion = () => {
  const { token, loading } = useAuthToken();
  const navigate = useNavigate();
  const location = useLocation();

  // define state
  const [title, setTitle] = useState(new URLSearchParams(location.search).get('title') || '');
  const [religions, setReligions] = useState([]);
  const [filteredReligion, setFilteredReligion] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [entriesPerPage, setEntriesPerPage] = useState(10);
  const [limit, setLimit] = useState(10);
  const [sortField, setSortField] = useState('id');
  const [sortOrder, setSortOrder] = useState('ASC');
  const [popupMessage, setPopupMessage] = useState('');

  useEffect(() => {
    const fetchReligion = async () => {
      try {
        const queryParams = new URLSearchParams();
        if (title) {
          queryParams.set('title', title);
        } else {
          queryParams.set('page', currentPage);
          queryParams.set('pageSize', entriesPerPage);
          queryParams.set('sort_by', sortField);
          queryParams.set('order_by', sortOrder);
          queryParams.set('limit', limit);
        }

        const response = await fetch(`${LIVE_URL}/admin/religion/list?${queryParams}`, {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });

        const data = await response.json();

        if (response.ok && Array.isArray(data.data.religion)) {
          setReligions(data.data.religion);
          setFilteredReligion(data.data.religion);
          setTotalPages(Math.ceil(data.data.pagination.totalCount / entriesPerPage));
        } else {
          setReligions([]);
          setFilteredReligion([]);
          setTotalPages(1);
        }
      } catch (error) {
        setReligions([]);
        setFilteredReligion([]);
        setTotalPages(1);
      }
    };
    if (!loading && token) {
      fetchReligion();
    }
  }, [token, loading, currentPage, title, entriesPerPage, limit, sortField, sortOrder]);

  useEffect(() => {
    if (religions) {
      const filtered = religions.filter(religion =>
        religion.title.toLowerCase().includes(title.toLowerCase())
      );
      setFilteredReligion(filtered);
    } else {
      setFilteredReligion(religions);
    }
  }, [title, religions]);

  useEffect(() => {
    if (location.search) {
      const params = new URLSearchParams(location.search);
      const message = params.get('message');
      if (message) {
        setPopupMessage(decodeURIComponent(message));
        setTimeout(() => {
          setPopupMessage('');
          navigate({ search: '' });
        }, 2000); 
      }
    }
  }, [location.search, navigate]);

  const handleLimitChange = (event) => {
    const newLimit = parseInt(event.target.value, 10);
    setLimit(newLimit);
    setEntriesPerPage(newLimit);
    setCurrentPage(1);
    setTotalPages(Math.ceil(filteredReligion.length / newLimit));
  };


  // // handle search click event
  // const handleSearchClick = () => {
  //   document.getElementById('search').focus();
  // }

  // handle clear search event
  const handleClearSearch = () => {
    setTitle('');
    document.getElementById('search').focus();
    updateUrl('');
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handlePrevious = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNext = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  // Added function to go two pages back
  const handlePreviousTwo = () => {
    if (currentPage > 2) {
      setCurrentPage(currentPage - 2);
    } else if (currentPage === 2) {
      setCurrentPage(1);
    }
  };

  // Added function to go two pages forward
  const handleNextTwo = () => {
    if (currentPage < totalPages - 1) {
      setCurrentPage(currentPage + 2);
    } else if (currentPage === totalPages - 1) {
      setCurrentPage(totalPages);
    }
  };

  const getPageNumbers = () => {
    const pageNumbers = [];
    if (currentPage > 1) {
      pageNumbers.push(currentPage - 1);
    }
    pageNumbers.push(currentPage);
    if (currentPage < totalPages) {
      pageNumbers.push(currentPage + 1);
    }
    return pageNumbers;
  };

  const updateUrl = (search) => {
    const params = new URLSearchParams(location.search);
    if (search) {
      params.set('title', search);
    } else {
      params.delete('title');
    }
    navigate({ search: params.toString() });
  };

  useEffect(() => {
    updateUrl(title);
  }, [title]);

  // const exportToExcel = () => {
  //   const worksheet = XLSX.utils.json_to_sheet(filteredReligion);
  //   const workbook = XLSX.utils.book_new();
  //   XLSX.utils.book_append_sheet(workbook, worksheet, "Religion");
  //   const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
  //   const data = new Blob([excelBuffer], { type: "application/octet-stream" });
  //   saveAs(data, "Religion.xlsx");
  // };

  return (
    <>
      <div className={`${styles.list_religion}`}>
      {popupMessage && (
          <div className={`${styles.update_message}`}>
            <p>{popupMessage}</p>
          </div>
        )}
        <header>
          <h2>Religion List Management</h2>
        </header>
        {/* entry section */}
        <section className={`${styles.section}`}>
          <div className={`${styles.user_entry}`}>
            <div className={`${styles.entries}`}>
              <label htmlFor="limit">Show</label>
              <select value={limit} onChange={handleLimitChange}>
                <option value="10">10</option>
                <option value="25">25</option>
                <option value="50">50</option>
                <option value="100">100</option>
              </select>
              <label htmlFor="entries">entries</label>
            </div>
            {/* <div className={`${styles.search_section}`}> */}
              <div className={`${styles.search}`}>
                <label htmlFor="search">Search: </label>
                <input
                  type="text"
                  id='search'
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  // onClick={handleSearchClick}
                />
                {title && <FaTimes className={styles.closeIcon} onClick={handleClearSearch} />}
              </div>
              {/* <div className={`${styles.export_button}`}>
                <button onClick={exportToExcel}>Export</button>
              </div>
            </div> */}
          </div>

          {/* user information section */}
          <div className={`${styles.user_info}`}>
            <ProductTable
              religions={filteredReligion}
              setReligions={setReligions}
              rows={entriesPerPage}
              title={title}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              sortField={sortField}
              sortOrder={sortOrder}
              setSortField={setSortField}
              setSortOrder={setSortOrder}
            />
          </div>

          {/* pagination section */}
          <div className={`${styles.pagination}`}>
            <button onClick={handlePreviousTwo} disabled={currentPage === 1}><FiChevronsLeft /></button>
            <button onClick={handlePrevious} disabled={currentPage === 1}><FiChevronLeft /></button>
            {getPageNumbers().map((page) => (
              <button
                key={page}
                onClick={() => handlePageChange(page)}
                className={currentPage === page ? styles.active : ''}
                disabled={page > totalPages}
              >
                {page}
              </button>
            ))}
            <button onClick={handleNext} disabled={currentPage === totalPages}><FiChevronRight /></button>
            <button onClick={handleNextTwo} disabled={currentPage === totalPages || currentPage === totalPages - 1}><FiChevronsRight /></button>
          </div>
        </section>
      </div>
    </>
  )
}

export default ListReligion;
