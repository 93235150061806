import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { LIVE_URL } from '../../../config/config';
import useAuthToken from "../../../hooks/useAuthToken";
import Spinner from '../spinner/Spinner';
import styles from "./css/AddPlan.module.scss";

const AddPlan = () => {
  // select option state
  const { token } = useAuthToken();
  const { id } = useParams();
  const navigate = useNavigate();

  const [selectedStatus, setSelectedStatus] = useState('');
  const [title, setTitle] = useState('');
  const [amount, setAmount] = useState('');
  const [dayLimit, setDayLimit] = useState('');
  const [description, setDescription] = useState('');
  const [popupMessage, setPopupMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [toggles, setToggles] = useState({
    filterInclude: false,
    audioVideo: false,
    directChat: false,
    chat: false,
    likeMenu: false,
  });

  useEffect(() => {
    if (id) {
      setLoading(true);
      fetch(`${LIVE_URL}/admin/plan/${id}`, {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      })
        .then(response => response.json())
        .then(data => {
          setTitle(data.data.title || '');
          setAmount(data.data.amount || '');
          setDayLimit(data.data.day_limit || '');
          setDescription(data.data.description || '');
          setSelectedStatus(data.data.status || '0');
          setToggles({
            filterInclude: data.data.filter_include || false,
            audioVideo: data.data.audio_video || false,
            directChat: data.data.direct_chat || false,
            chat: data.data.chat || false,
            likeMenu: data.data.is_like || false,
          });
        })
        .catch(error => console.error('Error fetching plan data:', error))
        .finally(() => setLoading(false));
    }
  }, [id, token]);

  useEffect(() => {
    let timer;
    if (popupMessage) {
      const popupElement = document.getElementById('popup-message');
      if (popupElement) {
        popupElement.style.opacity = '1';
      }
      timer = setTimeout(() => {
        if (popupElement) {
          popupElement.style.opacity = '0';
        }
        setTimeout(() => {
          setPopupMessage('');
        }, 500);
      }, 2000);
    }
    return () => clearTimeout(timer);
  }, [popupMessage]);

  const handleSelectChange = (event) => {
    setSelectedStatus(event.target.value);
  };

  const handleTitleChange = (event) => {
    setTitle(event.target.value);
  };

  const handleAmountChange = (event) => {
    setAmount(event.target.value);
  };

  const handleDayLimitChange = (event) => {
    setDayLimit(event.target.value);
  };

  const handleDescriptionChange = (event) => {
    setDescription(event.target.value);
  };

  const handletoggleswitch = (key) => {
    setToggles(prevState => ({
      ...prevState,
      [key]: !prevState[key]
    }));
  };

  const handleSubmit = async () => {
    // Function to handle form submission
    setLoading(true);
    const planData = {
      id,
      title,
      amount,
      day_limit: Number(dayLimit),
      description,
      status: selectedStatus,
      filter_include: toggles.filterInclude,
      audio_video: toggles.audioVideo,
      direct_chat: toggles.directChat,
      chat: toggles.chat,
      is_like: toggles.likeMenu,
    };

    try {
      const response = await fetch(`${LIVE_URL}/admin/plan/update`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify(planData),
      });

      if (response.ok) {
        const successMessage = 'Plan updated successfully.';
        navigate(`/home/list-plan?message=${encodeURIComponent(successMessage)}`);
      } else {
        setPopupMessage('Failed to update plan.');
      }
    } catch (error) {
      console.error('Error updating plan:', error);
      setPopupMessage('Error updating plan.');
    } finally {
      setLoading(false);
    }
  };

  const options = [
    { value: '1', text: 'Publish' },
    { value: '0', text: 'UnPublish' }
  ]

  if (loading) {
    return <Spinner />;
  }

  return (
    <>
      <div id="popup-message" className={`${styles.update_message} ${popupMessage.includes('success')}`}>
        {popupMessage && (
          <p>{popupMessage}</p>
        )}
      </div>
      <div className={`${styles.add_plan}`}>
        <header>
          <h2>Plan Management</h2>
        </header>
        <section className={`${styles.section}`}>
          <div className={`${styles.main}`}>
            <div>
              <label htmlFor="title">Plan Title</label><br />
              <input
                type="text"
                id="title"
                value={title}
                onChange={handleTitleChange}
              /><br />
            </div>
            <div>
              <label htmlFor="amount">Plan Amount</label><br />
              <input
                type="number"
                id="amount"
                value={amount}
                onChange={handleAmountChange}
              /><br />
            </div>
            <div>
              <label htmlFor="dayLimit">Day Limit</label><br />
              <input
                type="number"
                id="dayLimit"
                value={dayLimit}
                onChange={handleDayLimitChange}
              /><br />
            </div>
          </div>
          <div className={`${styles.textarea}`}>
            <label htmlFor="description">Plan Description</label><br />
            <textarea
              className="scrollable_textarea"
              id="description"
              value={description}
              onChange={handleDescriptionChange}
            ></textarea><br />
          </div>
          <div className={`${styles.plan_option}`}>
            {Object.keys(toggles).map((key, index) => (
              <div key={index}>
                <label htmlFor={key}>{key.split(/(?=[A-Z])/).join(' ')} ?</label><br />
                <div className={styles.circle1}>
                  <div
                    className={`${styles.circle2} ${toggles[key] ? styles.toggled : ''}`}
                    onClick={() => handletoggleswitch(key)}
                  ></div>
                </div>
              </div>
            ))}
          </div>
          <label htmlFor="status">Plan status</label><br />
          <select id="select" value={selectedStatus} onChange={handleSelectChange} required>
            <option value="">Select Status</option>
            {options.map((option, index) => (
              <option key={index} value={option.value}>
                {option.text}
              </option>
            ))}
          </select>
          <div>
            <button onClick={handleSubmit}>Update Plan</button>
          </div>
        </section>
      </div>
    </>
  )
}

export default AddPlan
