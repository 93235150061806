import { saveAs } from 'file-saver';
import React, { useEffect, useState } from 'react';
import { FaTimes } from 'react-icons/fa';
import { FiChevronLeft, FiChevronRight, FiChevronsLeft, FiChevronsRight } from 'react-icons/fi';
import { useLocation, useNavigate } from 'react-router-dom';
import * as XLSX from 'xlsx';
import { LIVE_URL } from "../../../config/config";
import useAuthToken from '../../../hooks/useAuthToken';
import styles from "./css/UserList.module.scss";
import UserTable from "./datatable/UserTable";

const UserList = () => {
  const { token, loading } = useAuthToken();
  const navigate = useNavigate();
  const location = useLocation();

  // define state
  const [email, setEmail] = useState(new URLSearchParams(location.search).get('email') || '');
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [entriesPerPage, setEntriesPerPage] = useState(10);
  const [limit, setLimit] = useState(10);
  const [sortField, setSortField] = useState('id');
  const [sortOrder, setSortOrder] = useState('ASC');

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const queryParams = new URLSearchParams();
        if (email) {
          queryParams.set('email', email);
        } else {
          queryParams.set('page', currentPage);
          queryParams.set('pageSize', entriesPerPage);
          queryParams.set('sort_by', sortField);
          queryParams.set('order_by', sortOrder);
          queryParams.set('limit', limit);
        }

        const response = await fetch(`${LIVE_URL}/admin/list-user?${queryParams}`, {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });

        const data = await response.json();

        if (response.ok && data.data && Array.isArray(data.data.user)) {
          setUsers(data.data.user);
          setFilteredUsers(data.data.user);
          setTotalPages(Math.ceil(data.data.pagination.totalCount / entriesPerPage));
        } else {
          setUsers([]);
          setFilteredUsers([]);
          setTotalPages(1);
        }
      } catch (error) {
        console.log(error)
        console.error('Error fetching users:', error);
        setUsers([]);
        setFilteredUsers([]);
        setTotalPages(1);
      }
    };

    if (!loading && token) {
      fetchUsers();
    }
  }, [token, loading, currentPage, email, entriesPerPage, limit, sortField, sortOrder]);

  useEffect(() => {
    if (users) {
      const filtered = users.filter(user =>
        user.email.toLowerCase().includes(email.toLowerCase())
      );
      setFilteredUsers(filtered);
    }
  }, [email, users]);

  // // handle entries per page change
  // const handleEntriesChange = (event) => {
  //   setEntriesPerPage(parseInt(event.target.value, 10));
  //   setCurrentPage(1);
  // };

  const handleLimitChange = (event) => {
    const newLimit = parseInt(event.target.value, 10);
    setLimit(newLimit);
    setEntriesPerPage(newLimit);
    setCurrentPage(1);
    setTotalPages(Math.ceil(filteredUsers.length / newLimit));
  };

  // // handle search click event
  // const handleSearchClick = () => {
  //   document.getElementById('search').focus();
  // }

  // handle clear search event
  const handleClearSearch = () => {
    setEmail('');
    document.getElementById('search').focus();
    // updateUrl('');
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handlePrevious = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNext = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousTwo = () => {
    if (currentPage > 2) {
      setCurrentPage(currentPage - 2);
    } else if (currentPage === 2) {
      setCurrentPage(1);
    }
  };

  const handleNextTwo = () => {
    if (currentPage < totalPages - 1) {
      setCurrentPage(currentPage + 2);
    } else if (currentPage === totalPages - 1) {
      setCurrentPage(totalPages);
    }
  };

  const getPageNumbers = () => {
    const pageNumbers = [];
    if (currentPage > 1) {
      pageNumbers.push(currentPage - 1);
    }
    pageNumbers.push(currentPage);
    if (currentPage < totalPages) {
      pageNumbers.push(currentPage + 1);
    }
    return pageNumbers;
  };

  const updateUrl = (search) => {
    const params = new URLSearchParams(location.search);
    if (search) {
      params.set('email', search);
    } else {
      params.delete('email');
    }
    navigate({ search: params.toString() });
  };

  useEffect(() => {
    updateUrl(email);
  }, [email]);

  // function export to excel
  const exportToExcel = async () => {
    try {
      const response = await fetch(`${LIVE_URL}/user/users/list`, {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });

      const data = await response.json();

      if (response.ok && data.data && Array.isArray(data.data.user)) {
        const allUsers = data.data.user;
        const worksheet = XLSX.utils.json_to_sheet(allUsers);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Users");
        const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
        const dataBlob = new Blob([excelBuffer], { type: "application/octet-stream" });
        saveAs(dataBlob, "users.xlsx");
      } else {
        console.error('Failed to fetch all users for export');
      }
    } catch (error) {
      console.error('Error fetching all users for export:', error);
    }
  };

  return (
    <>
      <div className={`${styles.user}`}>
        <header>
          <h2>User List Management</h2>
        </header>
        {/* entry section */}
        <section className={`${styles.section}`}>
          <div className={`${styles.user_entry}`}>
            <div className={`${styles.entries}`}>
              <label htmlFor="limit">Show</label>
              <select value={limit} onChange={handleLimitChange}>
                <option value="10">10</option>
                <option value="25">25</option>
                <option value="50">50</option>
                <option value="100">100</option>
              </select>
              <label htmlFor="entries">entries</label>
            </div>
            <div className={`${styles.search_section}`}>
              <div className={`${styles.search}`}>
                <label htmlFor="search">Search: </label>
                <input
                  type="text"
                  id='search'
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  // onClick={handleSearchClick}
                />
                {email && <FaTimes className={styles.closeIcon} onClick={handleClearSearch} />}
              </div>
              <div className={`${styles.export_button}`}>
                <button onClick={exportToExcel}>Export</button>
              </div>
            </div>
          </div>

          {/* user information section */}
          <div className={`${styles.user_info}`}>
            <UserTable
              apiEndpoint={`${LIVE_URL}/admin/list-user`}
              users={filteredUsers}
              setUsers={setUsers}
              rows={entriesPerPage}
              email={email}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              sortField={sortField}
              sortOrder={sortOrder}
              setSortField={setSortField}
              setSortOrder={setSortOrder}
            />
          </div>

          {/* pagination section */}
          <div className={`${styles.pagination}`}>
            <button onClick={handlePreviousTwo} disabled={currentPage === 1}><FiChevronsLeft /></button>
            <button onClick={handlePrevious} disabled={currentPage === 1}><FiChevronLeft /></button>
            {getPageNumbers().map((page) => (
              <button
                key={page}
                onClick={() => handlePageChange(page)}
                className={currentPage === page ? styles.active : ''}
                disabled={page > totalPages}
              >
                {page}
              </button>
            ))}
            <button onClick={handleNext} disabled={currentPage === totalPages}><FiChevronRight /></button>
            <button onClick={handleNextTwo} disabled={currentPage === totalPages || currentPage === totalPages - 1}><FiChevronsRight /></button>
          </div>
        </section>
      </div>
    </>
  )
}

export default UserList
