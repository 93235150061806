import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { LIVE_URL } from '../../../config/config';
import useAuthToken from '../../../hooks/useAuthToken';
import Spinner from '../spinner/Spinner';
import styles from "./css/AddInterest.module.scss";

const AddInterest = () => {
  const { token } = useAuthToken();
  const { id } = useParams();

  const [fileName, setFileName] = useState('No file chosen');
  const [title, setTitle] = useState('');
  const [file, setFile] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState('');
  const [filePreview, setFilePreview] = useState('');
  const [popupMessage, setPopupMessage] = useState('');
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const MAX_FILE_SIZE = 3 * 1024 * 1024;

  // Function to reset form fields
  const resetForm = () => {
    setFileName('No file chosen');
    setTitle('');
    setFile(null);
    setSelectedStatus('');
    setFilePreview('');
    setPopupMessage('');
    setErrors({});
  };

  useEffect(() => {
    if (id) {
      const fetchInterestData = async () => {
        try {
          setLoading(true);
          const response = await fetch(`${LIVE_URL}/admin/interests/${id}`, {
            headers: {
              'Authorization': `Bearer ${token}`,
            },
          });
          const data = await response.json();
          if (response.ok) {
            const interestData = data.data;
            setTitle(interestData.title);
            setSelectedStatus(interestData.status ? 'true' : 'false');
            setFilePreview(interestData.image);
            setFileName(interestData.image.split('/').pop());
          }
        } catch (error) {
          console.error('Error fetching interest data:', error);
        } finally {
          setLoading(false);
        }
      };
      fetchInterestData();
    } else {
      resetForm();
      // setLoading(false);
    }
  }, [id, token]);

  useEffect(() => {
    let timer;
    if (popupMessage) {
      const popupElement = document.getElementById('popup-message');
      if (popupElement) {
        popupElement.style.opacity = '1';
      }
      timer = setTimeout(() => {
        if (popupElement) {
          popupElement.style.opacity = '0';
        }
        setTimeout(() => {
          setPopupMessage('');
        }, 500);
      }, 2000);
    }
    return () => clearTimeout(timer);
  }, [popupMessage]);

  const handleSelectChange = (event) => {
    setSelectedStatus(event.target.value);
    setErrors((prevErrors) => ({ ...prevErrors, selectedStatus: '' }));
  };

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      if (selectedFile.size > MAX_FILE_SIZE) {
        setErrors((prevErrors) => ({ ...prevErrors, file: "File size exceeds 3MB limit." }));
        setFileName('No file chosen');
        setFile(null);
        setFilePreview('');
      } else {
        setFileName(selectedFile.name);
        setFile(selectedFile);
        setFilePreview("");
        setErrors((prevErrors) => ({ ...prevErrors, file: '' }));
      }
    } else {
      setFileName('No file chosen');
      setFile(null);
      setFilePreview('');
    }
  };

  const handleTitleChange = (event) => {
    setTitle(event.target.value);
    setErrors((prevErrors) => ({ ...prevErrors, title: '' }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    const newErrors = {};
    if (!file && !id) newErrors.file = "📸 Don't forget to add an image!";
    if (!title) newErrors.title = "🖋️ Don't Forget the Title!";
    if (!selectedStatus) newErrors.selectedStatus = '❗️Status field cannot be blank.';
    setErrors(newErrors);

    if (Object.keys(newErrors).length > 0) {
      setPopupMessage('Please fill in all required fields');
      setLoading(false);
      return;
    }

    const formData = new FormData();
    formData.append('title', title);
    formData.append('id', id);
    formData.append('type', "interests");
    formData.append('status', selectedStatus);
    if (file) {
      formData.append('image', file);
    }

    try {
      const url = id
        ? `${LIVE_URL}/admin/interests/update`
        : `${LIVE_URL}/admin/interests/create`;

      const method = 'POST';

      const response = await fetch(url, {
        method,
        headers: {
          'Authorization': `Bearer ${token}`,
        },
        body: formData,
      });

      if (response.ok) {
        const successMessage = id ? "Interest updated successfully." : "Interest saved successfully.";
        navigate(`/home/list-interest?message=${encodeURIComponent(successMessage)}`);

      } else {
        setPopupMessage(`Failed to save interest. Status: ${response.status}`);
      }
    } catch (error) {
      setPopupMessage('Error saving interest.');
    } finally {
      setLoading(false);
    }
  };

  const options = [
    { value: 'true', label: 'Publish' },
    { value: 'false', label: 'UnPublish' }
  ];

  if (loading) {
    return <Spinner />;
  }
  return (
    <>
      <div id="popup-message" className={`${styles.update_message} ${popupMessage.includes('success')}`}>
        {popupMessage && (
          <p>{popupMessage}</p>
        )}
      </div>
      <div className={`${styles.add_interest}`}>
        <header>
          <h2>Interest Management</h2>
        </header>
        <section className={`${styles.section}`}>
          <label htmlFor="">Interest Image</label>
          <div className={`${styles.fileInputContainer}`}>
            <input type="file" id="file" onChange={handleFileChange} />
            <label htmlFor="file">Choose File</label>
            <span id="file-name">{fileName}</span>
          </div>
          {errors.file && <p className={styles.error}>{errors.file}</p>}
          <br />
          <label htmlFor="title">Interest Title</label><br />
          <input
            type="text"
            id="title"
            value={title}
            onChange={handleTitleChange}
          />
          {errors.title && <p className={styles.error}>{errors.title}</p>}
          <br /><br />
          <label htmlFor="status">Interest Status</label><br />
          <select value={selectedStatus} onChange={handleSelectChange}>
            <option value="" >Select Status</option>
            {options.map((option, index) => (
              <option key={index} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
          {errors.selectedStatus && <p className={styles.error}>{errors.selectedStatus}</p>}
          <br />
          <button onClick={handleSubmit}>{id ? 'Update Interest' : 'Add Interest'}</button>
        </section>
      </div>
    </>
  );
}

export default AddInterest;

