import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import React, { useEffect, useState } from 'react';
import { BiSolidTrashAlt } from "react-icons/bi";
import { HiMiniPencilSquare } from "react-icons/hi2";
import { useNavigate } from 'react-router-dom';
import { LIVE_URL } from "../../../../config/config";
import useAuthToken from '../../../../hooks/useAuthToken';
import CustomModel from "../../customModel/CustomModel";
import styles from './css/InterestTable.module.scss';

const InterestTable = ({ interests, rows, setInterests, currentPage, setCurrentPage, sortField, sortOrder, setSortField, setSortOrder }) => {
  const { token } = useAuthToken();
  const navigate = useNavigate();
  const [popupMessage, setPopupMessage] = useState('');
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedInterestId, setSelectedInterestId] = useState(null);

  useEffect(() => {
    let timer;
    if (popupMessage) {
      const popupElement = document.getElementById('popup-message');
      if (popupElement) {
        popupElement.style.opacity = '1';
      }
      timer = setTimeout(() => {
        if (popupElement) {
          popupElement.style.opacity = '0';
        }
        setTimeout(() => {
          setPopupMessage('');
        }, 500);
      }, 2000);
    }
    return () => clearTimeout(timer);
  }, [popupMessage]);

  const handleEdit = (id) => {
    navigate(`/home/add-interest/${id}`);
  };

  const handleDeleteClick = (id) => {
    setSelectedInterestId(id);
    setModalOpen(true);
  };

  const handleDeleteConfirm = async () => {
    setModalOpen(false);
    if (selectedInterestId !== null) {
      try {
        const response = await fetch(`${LIVE_URL}/admin/interests/delete/${selectedInterestId}`, {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });

        if (response.status === 200) {
          setInterests(prevInterests => prevInterests.filter(interest => interest.id !== selectedInterestId));
          setPopupMessage('Interest deleted successfully.');
        } else {
          setPopupMessage(`Failed to delete interest. Status: ${response.status}`);
        }
      } catch (error) {
        setPopupMessage('Error deleting interest.');
      }
    }
  };

  const columns = [
    { field: 'id', header: 'Sr No.', className: styles['column-id'], sortable: true },
    {
      field: 'image', header: 'Interest Image', className: styles['column-image'],
      body: (rowData) => {
        return (
          <div className={`${styles.interest_image}`}>
            <img src={rowData.image} alt={rowData.title} />
          </div>
        );
      },
      sortable: false,
    },
    { field: 'title', header: 'Interest Title', className: styles['column-title'], sortable: true },
    {
      field: 'status',
      header: 'Interest Status',
      className: styles['column-status'],
      body: (rowData) => (
        <span className={rowData.status ? styles['status-published'] : styles['status-unpublished']}>
          {rowData.status ? 'Publish' : 'Unpublish'}
        </span>
      ),
      sortable: true,
    },
    {
      field: 'action',
      header: 'Action',
      className: styles['column-action'],
      body: (rowData) => (
        <div className={styles['action-icons']}>
          <HiMiniPencilSquare className={styles['pencil-icon']} onClick={() => handleEdit(rowData.id)} />
          <BiSolidTrashAlt className={styles['trash-icon']} onClick={() => handleDeleteClick(rowData.id)} />
        </div>
      ),
      sortable: false,
    },
  ];

  const handlePageChange = (event) => {
    setCurrentPage(event.page + 1);
  };

  const handleSort = (event) => {
    setSortField(event.sortField);
    setSortOrder(event.sortOrder === 1 ? 'ASC' : 'DESC');
  };

  return (
    <>
      <div id="popup-message" className={`${styles.delete_message} ${popupMessage.includes()}`}>
        {popupMessage && (
          <p>{popupMessage}</p>
        )}
      </div>
      <div className={`${styles.main}`}>
        <DataTable
          className={`${styles.datatable}`}
          value={interests}
          rows={rows}
          first={(currentPage - 1) * rows}
          onPage={handlePageChange}
          totalRecords={interests.length}
          sortField={sortField}
          sortOrder={sortOrder === 'ASC' ? 1 : -1}
          onSort={handleSort}
          emptyMessage={<div className={styles['no-data-message']}>No data to display.</div>}
        >

          {columns.map((col, i) => (
            <Column
              key={i}
              field={col.field}
              header={col.header}
              sortable={col.sortable}
              headerClassName={`${styles['column-header-small']} ${col.className}`}
              bodyClassName={col.className}
              body={col.body}
            />
          ))}
        </DataTable>
      </div>
      <CustomModel
        isOpen={modalOpen}
        onRequestClose={() => setModalOpen(false)}
        onConfirm={handleDeleteConfirm}
      />
    </>
  )
}

export default InterestTable;

