import React, { useEffect, useState } from 'react';
import { FaTimes } from "react-icons/fa";
import { FiChevronLeft, FiChevronRight, FiChevronsLeft, FiChevronsRight } from 'react-icons/fi';
import { useLocation, useNavigate } from 'react-router-dom';
import { LIVE_URL } from '../../../config/config';
import useAuthToken from '../../../hooks/useAuthToken';
import styles from "./css/ListFAQ.module.scss";
import ProductTable from './datatable/ProductTable';

const ListFAQ = () => {
  // Custom hook to get the authentication token
  const { token } = useAuthToken();
  const navigate = useNavigate();
  const location = useLocation();

  // define state
  const [question, setQuestion] = useState(new URLSearchParams(location.search).get('question') || '');
  const [faqs, setFaqs] = useState([]);
  const [filteredFaqs, setFilteredFaqs] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [entriesPerPage, setEntriesPerPage] = useState(10);
  const [limit, setLimit] = useState(10);
  const [sortField, setSortField] = useState('id');
  const [sortOrder, setSortOrder] = useState('ASC');
  const [popupMessage, setPopupMessage] = useState('');

  useEffect(() => {
    const fetchFaqs = async () => {
      try {
        const queryParams = new URLSearchParams();
        if (question) {
          queryParams.set('question', question);
        } else {
          queryParams.set('page', currentPage);
          queryParams.set('pageSize', entriesPerPage);
          queryParams.set('sort_by', sortField);
          queryParams.set('order_by', sortOrder);
          queryParams.set('limit', limit);
        }

        const response = await fetch(`${LIVE_URL}/admin/faq/list?${queryParams}`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });

        if (response.ok) {
          const data = await response.json();

          if (data?.data?.faq) {
            setFaqs(data.data.faq);
            setFilteredFaqs(data.data.faq);
            setTotalPages(Math.ceil(data.data.pagination.totalCount / entriesPerPage));
          } else {
            setFaqs([]);
            setFilteredFaqs([]);
            setTotalPages(1);
          }
        } else {
          setFaqs([]);
          setFilteredFaqs([]);
          setTotalPages(1);
        }
      } catch (error) {
        setFaqs([]);
        setFilteredFaqs([]);
        setTotalPages(1);
      }
    };
    fetchFaqs();
  }, [token, currentPage, question, entriesPerPage, limit, sortField, sortOrder]);

  useEffect(() => {
    if (faqs) {
      const filtered = faqs.filter(faq =>
        faq.question.toLowerCase().includes(question.toLowerCase())
      );
      setFilteredFaqs(filtered);
    } else {
      setFilteredFaqs(faqs)
    }
  }, [question, faqs]);

  useEffect(() => {
    if (location.search) {
      const params = new URLSearchParams(location.search);
      const message = params.get('message');
      if (message) {
        setPopupMessage(decodeURIComponent(message));
        setTimeout(() => {
          setPopupMessage('');
          navigate({ search: '' });
        }, 2000);
      }
    }
  }, [location.search, navigate]);

  const handleLimitChange = (event) => {
    const newLimit = parseInt(event.target.value, 10);
    setLimit(newLimit);
    setEntriesPerPage(newLimit);
    setCurrentPage(1);
    setTotalPages(Math.ceil(filteredFaqs.length / newLimit));
  };

  // handle clear search event
  const handleClearSearch = () => {
    setQuestion('');
    document.getElementById('search').focus();
    updateUrl('');
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handlePrevious = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNext = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  // Added function to go two pages back
  const handlePreviousTwo = () => {
    if (currentPage > 2) {
      setCurrentPage(currentPage - 2);
    } else if (currentPage === 2) {
      setCurrentPage(1);
    }
  };

  // Added function to go two pages forward
  const handleNextTwo = () => {
    if (currentPage < totalPages - 1) {
      setCurrentPage(currentPage + 2);
    } else if (currentPage === totalPages - 1) {
      setCurrentPage(totalPages);
    }
  };

  const getPageNumbers = () => {
    const pageNumbers = [];
    if (currentPage > 1) {
      pageNumbers.push(currentPage - 1);
    }
    pageNumbers.push(currentPage);
    if (currentPage < totalPages) {
      pageNumbers.push(currentPage + 1);
    }
    return pageNumbers;
  };

  const updateUrl = (search) => {
    const params = new URLSearchParams(location.search);
    if (search) {
      params.set('question', search);
    } else {
      params.delete('question');
    }
    navigate({ search: params.toString() });
  };

  // useEffect(() => {
  //   updateUrl(question);
  // }, [question]);

  return (
    <>
      <div className={`${styles.list_faq}`}>
        {popupMessage && (
          <div className={`${styles.update_message}`}>
            <p>{popupMessage}</p>
          </div>
        )}
        <header>
          <h2>FAQ List Management</h2>
        </header>
        {/* entry section */}
        <section className={`${styles.section}`}>
          <div className={`${styles.user_entry}`}>
            <div className={`${styles.entries}`}>
              <label htmlFor="limit">Show</label>
              <select value={limit} onChange={handleLimitChange}>
                <option value="10">10</option>
                <option value="25">25</option>
                <option value="50">50</option>
                <option value="100">100</option>
              </select>
              <label htmlFor="entries">entries</label>
            </div>
            <div className={`${styles.search}`}>
              <label htmlFor="search">Search: </label>
              <input
                type="text"
                id='search'
                value={question}
                onChange={(e) => setQuestion(e.target.value)}
              />
              {question && <FaTimes className={styles.closeIcon} onClick={handleClearSearch} />}
            </div>
          </div>

          {/* user information section */}
          <div className={`${styles.user_info}`}>
            <ProductTable
              faqs={filteredFaqs}
              setFaqs={setFaqs}
              rows={entriesPerPage}
              question={question}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              sortField={sortField}
              sortOrder={sortOrder}
              setSortField={setSortField}
              setSortOrder={setSortOrder}
            />
          </div>

          {/* pagination section */}
          <div className={`${styles.pagination}`}>
            <button onClick={handlePreviousTwo} disabled={currentPage === 1}>
              <FiChevronsLeft />
            </button>
            <button onClick={handlePrevious} disabled={currentPage === 1}><FiChevronLeft />
            </button>
            {getPageNumbers().map((page) => (
              <button
                key={page}
                onClick={() => handlePageChange(page)}
                className={currentPage === page ? styles.active : ''}
                disabled={page > totalPages}
              >
                {page}
              </button>
            ))}
            <button onClick={handleNext} disabled={currentPage === totalPages}><FiChevronRight />
            </button>
            <button onClick={handleNextTwo} disabled={currentPage === totalPages || currentPage === totalPages - 1}>
              <FiChevronsRight />
            </button>
          </div>
        </section>
      </div>
    </>
  )
}

export default ListFAQ
