import React from 'react';
import { Navigate, Route, Routes } from "react-router-dom";
import './App.css';
import AdminLogin from './component/adminlogin/AdminLogin';
import ForgotPassword from './component/adminlogin/ForgotPassword';
import OtpPage from "./component/adminlogin/OtpPage";
import ResetPassword from "./component/adminlogin/ResetPassword";
import Home from './component/home/Home';
import Account from './component/navbar component/account/Account';
import Settings from './component/navbar component/settings/Settings';
import Dashboard from "./component/sidebar component/dashboard/Dashboard";
import AddFAQ from "./component/sidebar component/faq/AddFAQ";
import ListFAQ from "./component/sidebar component/faq/ListFAQ";
import AddInterest from "./component/sidebar component/interest/AddInterest";
import ListInterest from './component/sidebar component/interest/ListInterest';
import AddLanguage from './component/sidebar component/language/AddLanguage';
import ListLanguage from './component/sidebar component/language/ListLanguage';
import AddPage from './component/sidebar component/page/AddPage';
import ListPage from './component/sidebar component/page/ListPage';
import Payment from "./component/sidebar component/payment/Payment";
import AddPlan from "./component/sidebar component/plan/AddPlan";
import ListPlan from "./component/sidebar component/plan/ListPlan";
import AddRelation from "./component/sidebar component/relation goal/AddRelation";
import ListRelation from "./component/sidebar component/relation goal/ListRelation";
import AddReligion from "./component/sidebar component/religion/AddReligion";
import ListReligion from "./component/sidebar component/religion/ListReligion";
import Report from "./component/sidebar component/report/Report";
import UserInfo from './component/sidebar component/user/UserInfo';
import UserList from "./component/sidebar component/user/UserList";
import useAuthToken from './hooks/useAuthToken';

function App() {
  const { token, loading } = useAuthToken();

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <Routes>
        <Route path="/admin-login" element={<AdminLogin />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/verify-otp" element={<OtpPage />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/home" element={token ? <Home /> : <Navigate to="/admin-login" />}>
        <Route index element={<Navigate to="dashboard" />} />
          <Route path="dashboard" element={<Dashboard />} />
          <Route path="add-interest" element={<AddInterest />} />
          <Route path="add-interest/:id" element={<AddInterest />} />
          <Route path="list-interest" element={<ListInterest />} />
          <Route path="add-language" element={<AddLanguage />} />
          <Route path="add-language/:id" element={<AddLanguage />} />
          <Route path="list-language" element={<ListLanguage />} />
          <Route path="add-religion" element={<AddReligion />} />
          <Route path="add-religion/:id" element={<AddReligion />} />
          <Route path="list-religion" element={<ListReligion />} />
          <Route path="add-relation" element={<AddRelation />} />
          <Route path="add-relation/:id" element={<AddRelation />} />
          <Route path="list-relation" element={<ListRelation />} />
          <Route path="add-faq" element={<AddFAQ />} />
          <Route path="add-faq/:id" element={<AddFAQ />} />
          <Route path="list-faq" element={<ListFAQ />} />
          {/* <Route path="add-plan" element={<AddPlan />} /> */}
          <Route path="add-plan/:id" element={<AddPlan />} />
          <Route path="list-plan" element={<ListPlan />} />
          <Route path="payment" element={<Payment />} />
          <Route path="report" element={<Report />} />
          <Route path="user" element={<UserList />} />
          <Route path="user-info/:id" element={<UserInfo/>}/>
          <Route path='add-page' element={<AddPage/>}/>
          <Route path='add-page/:id' element={<AddPage/>}/>
          <Route path='list-page' element={<ListPage/>}/>
          <Route path="account" element={<Account />} />
          <Route path="settings" element={<Settings />} />
          <Route path="*" element={<Navigate to="/admin-login" />} />
        </Route>
      </Routes>
    </>
  );
}

export default App;
