import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import React, { useEffect, useState } from 'react';
import { HiMiniPencilSquare } from "react-icons/hi2";
import { useNavigate } from 'react-router-dom';
import useAuthToken from '../../../../hooks/useAuthToken';
import styles from './css/ProductTable.module.scss';

const ProductTable = ({ apiEndpoint, rows, searchText, pages }) => {
    const { token, loading } = useAuthToken();
    const [products, setProducts] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchPages = async () => {
            try {
                const response = await fetch(apiEndpoint, {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    },
                });

                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();

                setProducts(data.data.getPage);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        if (!loading && token) {
            fetchPages();
        }
    }, [apiEndpoint, rows, token, loading]);

    // Filter products based on searchText
    const filteredPages = pages.filter(page =>
        page.title.toLowerCase().includes(searchText.toLowerCase())
    );

    // Custom body template for the Page Status column
    const pageStatusTemplate = (rowData) => {
        const statusClass = rowData.status === true ? styles.publish : styles.unpublish;
        return (
            <span className={statusClass}>
                {rowData.status === true ? 'Publish' : 'UnPublish'}
            </span>
        );
    };

    // Example edit and delete handlers
    const handleEdit = (id) => {
        navigate(`/home/add-page/${id}`);
    };

    const columns = [
        { field: 'id', header: 'Sr No.', className: styles['column-id'], sortable: true },
        { field: 'title', header: 'Page Name', className: styles['column-page-name'] },
        { field: 'description', header: 'Description', className: styles['column-description'] },
        {
            field: 'status',
            header: 'Page Status',
            className: `${styles['column-status']} ${styles['page-status']}`,
            body: pageStatusTemplate,
        },
        {
            field: 'action',
            header: 'Action',
            className: styles['column-action'],
            body: (rowData) => (
                <div className={styles['action-icons']}>
                    <HiMiniPencilSquare className={styles['user-icon']} onClick={() => handleEdit(rowData.id)} />
                    {/* <BiSolidTrashAlt className={styles['trash-icon']} onClick={() => handleDelete(rowData.id)} /> */}
                </div>
            ),
            sortable: false
        }
    ];

    return (
        <>
            {/* <div id="popup-message" className={`${styles.delete_message} ${popupMessage.includes('success') ? styles.success : styles.error}`}>
                {popupMessage && (
                    <p>{popupMessage}</p>
                )}
            </div> */}
            <div className={`${styles.main}`}>
                <DataTable
                    className={`${styles.datatable}`}
                    value={filteredPages}
                    rows={rows}
                    loading={loading}
                    emptyMessage={<div className={styles['no-data-message']}>No data to display.</div>}
                >
                    {columns.map((col, i) => (
                        <Column
                            key={i}
                            field={col.field}
                            header={col.header}
                            sortable={col.sortable}
                            headerClassName={`${styles['column-header-small']} ${col.className}`}
                            bodyClassName={col.className}
                            body={col.body}
                        />
                    ))}
                </DataTable>
            </div>
        </>
    )
}

export default ProductTable;



