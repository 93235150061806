import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { LIVE_URL } from '../../../config/config';
import useAuthToken from "../../../hooks/useAuthToken";
import Spinner from '../spinner/Spinner';
import styles from "./css/AddPage.module.scss";

const AddPage = () => {
  const { token } = useAuthToken();
  const { id } = useParams();
  const navigate = useNavigate();

  const [selectedStatus, setSelectedStatus] = useState('');
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [popupMessage, setPopupMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({
    title: '',
    description: '',
    status: ''
  });

  useEffect(() => {
    if (id) {
      setLoading(true);
      fetch(`${LIVE_URL}/admin/page/${id}`, {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      })
        .then(response => response.json())
        .then(data => {
          setTitle(data.data.title || '');
          setDescription(data.data.description || '');
          setSelectedStatus(data.data.status ? '1' : '0');
        })
        .catch(error => console.error('Error fetching page data:', error))
        .finally(() => setLoading(false));
    }
  }, [id, token]);

  useEffect(() => {
    let timer;
    if (popupMessage) {
      const popupElement = document.getElementById('popup-message');
      if (popupElement) {
        popupElement.style.opacity = '1';
      }
      timer = setTimeout(() => {
        if (popupElement) {
          popupElement.style.opacity = '0';
        }
        setTimeout(() => {
          setPopupMessage('');
        }, 500);
      }, 2000);
    }
    return () => clearTimeout(timer);
  }, [popupMessage]);

  const handleSelectChange = (event) => {
    setSelectedStatus(event.target.value);
  };

  const handleTitleChange = (event) => {
    setTitle(event.target.value);
  };

  const handleDescriptionChange = (event) => {
    setDescription(event.target.value);
  };

  // validation for required field
  const validate = () => {
    const errors = {
      title: '',
      description: '',
      status: ''
    };
    let isValid = true;

    if (!title.trim()) {
      errors.title = "🖋️ Don't Forget the Title!.";
      isValid = false;
    }

    if (!description.trim()) {
      errors.description = '💡 Please complete this.';
      isValid = false;
    }

    if (!selectedStatus) {
      errors.status = '❗️Status field cannot be blank.';
      isValid = false;
    }

    setErrors(errors);

    if (!isValid) {
      setPopupMessage('Please fill in all required fields.');
    }
    return isValid;
  };


  const handleSubmit = async () => {
    if (!validate()) {
      return;
    }
    // Function to handle form submission
    setLoading(true);
    const pageData = {
      id,
      title,
      description,
      status: selectedStatus,
    };

    try {
      const response = await fetch(`${LIVE_URL}/admin/page/update`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify(pageData),
      });

      if (response.ok) {
        const successMessage = 'Page updated successfully.';
        navigate(`/home/list-page?message=${encodeURIComponent(successMessage)}`);
      } else {
        setPopupMessage('Failed to update page.');
      }
    } catch (error) {
      console.error('Error updating page:', error);
      setPopupMessage('Error updating page.');
    } finally {
      setLoading(false);
    }
  };

  const options = [
    { value: '1', text: 'Publish' },
    { value: '0', text: 'UnPublish' }
  ]

  if (loading) {
    return <Spinner />;
  }

  return (
    <>
      <div id="popup-message" className={`${styles.error_message}`}>
        {popupMessage && (
          <p>{popupMessage}</p>
        )}
      </div>
      <div className={`${styles.add_page}`}>
        <header>
          <h2>Page Management</h2>
        </header>
        <section className={`${styles.section}`}>

          <label htmlFor="title">Enter Page Title</label><br />
          <input
            type="text"
            id="title"
            placeholder="Enter Page Title"
            value={title}
            onChange={handleTitleChange}
          />
          {errors.title && <p className={styles.error}>{errors.title}</p>}
          <br /><br/>

          <label htmlFor="status">Page status</label><br />
          <select id="select" value={selectedStatus} onChange={handleSelectChange} required>
            <option value="">Select Status</option>
            {options.map((option, index) => (
              <option key={index} value={option.value}>
                {option.text}
              </option>
            ))}
          </select>
          {errors.status && <p className={styles.error}>{errors.status}</p>}
          <br/><br/>
          <label htmlFor="description">Page Description</label><br />
          <textarea
            className="scrollable_textarea"
            value={description}
            onChange={handleDescriptionChange}
          ></textarea>
          {errors.description && <p className={styles.error}>{errors.description}</p>}
          <br />
          <button onClick={handleSubmit}>Update Page</button>
        </section>
      </div>
    </>
  )
}

export default AddPage
